import React from 'react';
import { Navigate } from 'react-router-dom';
import NotFoundView from 'src/container/errors/NotFoundView';
import Login from './container/Login';

const loginRoutes = [
  {
    path: '/',
    children: [
      { path: '/', element: <Login /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    children: [
      { path: '/login', element: <Login /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default loginRoutes;
