import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  IconButton
} from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from 'react-redux';
import { strings } from 'src/utils/strings';
import { useLocation, useNavigate } from 'react-router-dom';
import ChangePasswordForm from '../ChangePassword/ChangePassword';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2.4)
  },
  memberContainer: {},
  container: {
    marginBottom: '20px'
  },
  slotStyle: {
    marginRight: '5px',
    fontSize: '16px'
  },
  margin5x: {
    marginLeft: '10px',
    marginTop: '5px'
  },
  userInfo: {
    marginTop: '5px',
    flexDirection: 'row',
    display: 'flex'
  }
}));

const ChangePassword = props => {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <Page className={classes.root} title={strings.change_password}>
      <Container maxWidth={false}>
        <Grid
          alignItems="center"
          className={classes.container}
          justify="space-between"
          container
        >
          <Grid item container alignItems={'center'}>
            <IconButton
              onClick={() => {
                navigate('/app/arts', { replace: true });
              }}
            >
              <ArrowBackIcon fontSize={'large'} color={'primary'} />
            </IconButton>
            <Typography color="textPrimary" variant="h6">
              {strings.change_password}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ChangePasswordForm />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
