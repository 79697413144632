import React from 'react';
import {
  Box,
  Container,
  makeStyles,
  Grid,
  Typography,
  IconButton
} from '@material-ui/core';
import { connect } from 'react-redux';
import Page from 'src/components/Page';
import { strings } from 'src/utils/strings';
import UserList from './UserList';
import { CommonButton } from 'src/components/CommonButton';
import { useNavigate } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import { FileDrop } from 'react-file-drop';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  REQUEST_EXPORT_USER,
  REQUEST_GET_USERS_LIST,
  REQUEST_IMPORT_USER
} from 'src/redux/actions/userActions';
import CancelIcon from '@material-ui/icons/Cancel';
import { formatBytes } from 'src/constants/utils';
import idx from 'idx';
import { withSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2.4)
  },
  headerContainer: {
    paddingRight: '15px'
  },
  arrivalStyle: {
    marginRight: '5px',
    marginLeft: '10px',
    fontSize: '16px'
  },
  margin5x: {
    marginLeft: '15px',
    marginTop: '5px'
  },
  slotStyle: {
    marginRight: '5px',
    fontSize: '16px'
  },
  textContainers: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  modalStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  dropContainer: {
    height: 200,
    width: '100%',
    border: '1.5px solid',
    borderColor: '#ffffff',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fileParentView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    marginLeft: '20%',
    marginRight: '20%',
    border: `1px solid ${'#8D6E63'}`,
    borderRadius: 20
  },
  fileCancelButton: { position: 'absolute', top: -8, right: '20%' },
  fileTextView: { width: '75%' },
  fileNameText: {
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  fileSizeText: { width: '100%' }
}));

const ListIndex = props => {
  const fileInputRef = React.useRef(null);
  const classes = useStyles();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [isExporting, setIsExporting] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const getFileIcon = () => {
    return (
      <DescriptionIcon
        style={{
          color: '#8D6E63',
          width: '25%',
          fontSize: '4.18rem'
        }}
      />
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const onFileInputChange = event => {
    const { files } = event.target;
    if (files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  const onClickImportUser = () => {
    const { importUser, getUsersList } = props;
    const formData = new FormData();
    formData.append('file', selectedFile);
    importUser(formData, res => {
      const resStatus = idx(res, _ => _.status) || '';
      const resMessage = idx(res, _ => _.data) || '';
      if (resStatus === 200) {
        props.enqueueSnackbar(resMessage, { variant: 'success' });
        getUsersList(25, 1, () => {});
      } else {
        const errMessage = idx(res, _ => _.message) || 'An error occured';
        props.enqueueSnackbar(errMessage, { variant: 'error' });
      }
      setOpen(false);
      setSelectedFile(null);
    });
  };

  const body = () => {
    return (
      <div className={classes.paper}>
        <input
          accept=".csv"
          onChange={onFileInputChange}
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
        />
        {selectedFile ? (
          <div className={classes.fileParentView}>
            <IconButton
              className={classes.fileCancelButton}
              onClick={() => {
                setSelectedFile(null);
              }}
            >
              <CancelIcon style={{ color: '#8D6E63', fontSize: '2rem' }} />
            </IconButton>
            {getFileIcon()}
            <div className={classes.fileTextView}>
              <Typography className={classes.fileNameText}>
                {selectedFile.name}
              </Typography>
              <Typography className={classes.fileSizeText} numberOfLines={1}>
                {formatBytes(selectedFile.size)}
              </Typography>
            </div>
          </div>
        ) : (
          <FileDrop
            onTargetClick={onTargetClick}
            onDragOver={event => console.log(event)}
            onDragLeave={event => console.log(event)}
            onDrop={files => console.log(files)}
          >
            <Grid className={classes.dropContainer} container>
              <DescriptionIcon fontSize={'large'} />
              <Typography style={{ color: 'black' }}>
                {strings.dropFiles}
              </Typography>
            </Grid>
          </FileDrop>
        )}

        <CommonButton
          style={{ height: '42px', display: 'flex', marginLeft: 'auto' }}
          disabled={!selectedFile || selectedFile === null}
          isLoading={props.importUserIsInProgress}
          onClick={onClickImportUser}
          title={strings.import}
          className={classes.margin5x}
        />
      </div>
    );
  };

  const onClickExportUser = () => {
    try {
      setIsExporting(true);
      const { exportUser } = props;
      exportUser(res => {
        if (res && res.size && res.type) {
          setIsExporting(false);
          props.enqueueSnackbar('Download successfully', {
            variant: 'success'
          });
        } else {
          setIsExporting(false);
          props.enqueueSnackbar('Download failed', {
            variant: 'error'
          });
        }
      });
    } catch (e) {
      setIsExporting(false);
      props.enqueueSnackbar('An error occured', {
        variant: 'error'
      });
    }
  };

  return (
    <Page className={classes.root} title={strings.users}>
      <Modal
        className={classes.modalStyle}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body()}
      </Modal>
      <Container maxWidth={false}>
        <Grid
          className={classes.headerContainer}
          alignItems="center"
          justify="space-between"
          container
        >
          <Grid item></Grid>
          <Grid item xs={8}>
            <Grid
              alignItems="center"
              justify={'flex-end'}
              direction={'row'}
              container
              sm={12}
            >
              <CommonButton
                style={{ height: '42px' }}
                isLoading={isExporting}
                disabled={isExporting}
                onClick={onClickExportUser}
                title={strings.export_user}
                className={classes.margin5x}
              />
              <CommonButton
                style={{ height: '42px' }}
                onClick={() => {
                  handleOpen();
                }}
                title={strings.import_user}
                className={classes.margin5x}
              />
              <CommonButton
                onClick={() => {
                  navigate('/app/addUser');
                }}
                style={{ height: '42px' }}
                title={strings.add_user}
                className={classes.margin5x}
              />
            </Grid>
          </Grid>
        </Grid>

        <Box mt={3}>
          <UserList />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  const { userReducer } = state;
  const {
    importUserList,
    importUserIsInProgress,
    importUserError
  } = userReducer;
  return {
    importUserList,
    importUserIsInProgress,
    importUserError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUsersList: (pageSize, pageNumber, callBack) =>
      dispatch({
        type: REQUEST_GET_USERS_LIST,
        pageSize,
        pageNumber,
        callBack
      }),
    exportUser: callBack => dispatch({ type: REQUEST_EXPORT_USER, callBack }),
    importUser: (data, callBack) =>
      dispatch({ type: REQUEST_IMPORT_USER, data, callBack })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListIndex));
