import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { CommonTextField } from 'src/components/CommonTextField';
import { CommonButton } from 'src/components/CommonButton';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  REQUEST_ADD_USER,
  REQUEST_GET_USERS_LIST,
  REQUEST_UPDATE_USER
} from 'src/redux/actions/userActions';
import { withSnackbar } from 'notistack';
import idx from 'idx';

const useStyles = makeStyles(theme => ({
  root: {},
  addbtnStyle: {
    paddingRight: '35px',
    paddingLeft: '35px'
  },
  cancelbtnStyle: {
    paddingRight: '35px',
    paddingLeft: '35px',
    backgroundColor: 'white',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#dfdfdf'
    }
  }
}));

const AddUser = props => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const AddUserSchema = Yup.object().shape({
    full_name: Yup.string()
      .nullable()
      .max(50, 'Too Long!')
      .required('Please enter full name'),
    email: Yup.string()
      .email('Please enter valid email address')
      .required('Please enter email'),
    password: Yup.string()
      .nullable()
      .required('Please enter password')
  });

  const UpdateUserSchema = Yup.object().shape({
    full_name: Yup.string()
      .nullable()
      .max(50, 'Too Long!')
      .required('Please enter full name'),
    email: Yup.string()
      .nullable()
      .max(50, 'Too Long!')
      .required('Please enter email')
  });

  const {
    className,
    handleAddUpdate,
    userResponse,
    stateList,
    genderList,
    ...rest
  } = props;

  const classes = useStyles();

  const sUserId = idx(state, _ => _._id) || null;
  const sUserName = idx(state, _ => _.userName) || '';
  const sEmail = idx(state, _ => _.email) || '';
  const sPassword = idx(state, _ => _.password) || '';
  const sRole = idx(state, _ => _.role) || '';
  const isViewMode = idx(state, _ => _.isViewMode) || false;

  const [values, setValues] = useState({
    full_name: sUserName,
    email: sEmail,
    password: sPassword,
    isSuperAdmin: sRole === 'superadmin'
  });
  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = event => {
    setErrors({ ...errors, [event.target.name]: '' });
    if (event.target.checked) {
      setValues({
        ...values,
        [event.target.name]: event.target.checked
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleAddClick = () => {
    const { addUser, updateUser, enqueueSnackbar } = props;
    const obj = { ...values };
    const UserSchema = state && state._id ? UpdateUserSchema : AddUserSchema;
    UserSchema.validate(obj, { abortEarly: false })
      .then(() => {
        setIsLoading(true);
        const data = {
          userName: values.full_name,
          email: values.email,
          password: values.password,
          role: values.isSuperAdmin ? 'superadmin' : 'admin'
        };
        if (state && state._id) {
          data._id = state._id;
          delete data.password;
          updateUser(data, res => {
            if (res.status === 200) {
              enqueueSnackbar('User updated successfully', {
                variant: 'success'
              });
              navigate('/app/users', { replace: true });
            } else {
              enqueueSnackbar(res.message, { variant: 'error' });
            }
            setIsLoading(false);
          });
        } else {
          addUser(data, res => {
            if (res.status === 200) {
              enqueueSnackbar('User added successfully', {
                variant: 'success'
              });
              navigate('/app/users', { replace: true });
            } else {
              enqueueSnackbar(res.message, { variant: 'error' });
            }
            setIsLoading(false);
          });
        }
      })
      .catch(e => {
        let errorObj = {};
        e.inner.forEach(el => {
          errorObj = {
            ...errorObj,
            [el.path]: el.message
          };
        });
        console.log(e);
        setErrors(errorObj);
      });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <CommonTextField
              name={'Full name'}
              value={'full_name'}
              disabled={isViewMode}
              onChange={e => handleChange(e)}
              md={12}
              error={errors['full_name']}
              fieldVal={values['full_name']}
            />
            <CommonTextField
              name={'Email'}
              value={'email'}
              disabled={isViewMode}
              onChange={e => handleChange(e)}
              md={12}
              error={errors['email']}
              fieldVal={values['email']}
            />
            {isViewMode || sUserId ? null : (
              <CommonTextField
                name={'Password'}
                value={'password'}
                disabled={isViewMode}
                onChange={e => handleChange(e)}
                md={12}
                error={errors['password']}
                fieldVal={values['password']}
                type={'password'}
              />
            )}
          </Grid>
        </CardContent>
        <Grid
          style={{
            marginBottom: '15px',
            pointerEvents: isViewMode ? 'none' : 'all'
          }}
          container
          justify="flex-end"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={values['isSuperAdmin']}
                onChange={e => handleChange(e)}
                name="isSuperAdmin"
                color="primary"
              />
            }
            label="Is SuperAdmin"
          />
        </Grid>
        <Divider />
        {isViewMode ? null : (
          <Box display="flex" justifyContent="flex-end" p={2}>
            <CommonButton
              themeColor={''}
              style={{ width: '130px' }}
              className={classes.cancelbtnStyle}
              title={'Cancel'}
              onClick={() => {
                navigate('/app/users', { replace: true });
              }}
            />
            <CommonButton
              style={{ width: '130px', marginLeft: '20px' }}
              className={classes.addbtnStyle}
              title={state && state._id ? 'Update' : 'Add'}
              isLoading={isLoading}
              onClick={() => {
                handleAddClick();
              }}
            />
          </Box>
        )}
      </Card>
    </form>
  );
};

AddUser.propTypes = {
  className: PropTypes.string,
  userdetails: PropTypes.object
};

const mapStateToProps = state => {
  const {} = state;
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    getUsersList: callBack =>
      dispatch({ type: REQUEST_GET_USERS_LIST, callBack }),
    addUser: (data, callBack) =>
      dispatch({ type: REQUEST_ADD_USER, data, callBack }),
    updateUser: (data, callBack) =>
      dispatch({ type: REQUEST_UPDATE_USER, data, callBack })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AddUser));
