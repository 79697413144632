import React from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export const CommonButton = props => {
  const {
    title,
    isLoading,
    progressStyle,
    progressColor,
    onClick,
    className,
    disabled,
    themeColor
  } = props;
  return (
    <Button
      style={{ ...props.style }}
      className={className}
      onClick={onClick}
      color={themeColor}
      variant="contained"
      disabled={disabled}
    >
      {isLoading ? (
        <CircularProgress
          style={progressStyle}
          color={progressColor}
          size={25}
        />
      ) : (
        <Typography
          style={{
            fontWeight: 'bold',
            color: themeColor === 'primary' ? 'white' : 'black',
            textTransform: 'uppercase',
            lineHeight: 1.75,
            fontSize: '0.875rem'
          }}
        >
          {title}
        </Typography>
      )}
    </Button>
  );
};

CommonButton.propTypes = {
  progressColor: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  themeColor: PropTypes.string,
  style: PropTypes.object
};

CommonButton.defaultProps = {
  progressColor: 'white',
  isLoading: false,
  disabled: false,
  themeColor: 'primary',
  style: { fontFamily: 'Roboto' }
};
