import React from 'react';
import { makeStyles, Typography, Grid, TextField } from '@material-ui/core';
import theme from 'src/theme';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  padding10x: {
    padding: '10px'
  },
  label: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: '5px'
    },
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 18
  },
  formControl: {
    display: 'flex'
  },
  labelGrid: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

export const CommonSelector = props => {
  const classes = useStyles();
  const {
    title,
    fieldVal,
    value,
    options,
    onChange,
    error,
    md,
    xs,
    gridProps
  } = props;
  return (
    <Grid item md={md} xs={xs} {...gridProps}>
      <TextField
        error={error}
        helperText={error}
        fullWidth
        name={value}
        onChange={onChange}
        required
        select
        SelectProps={{ native: true }}
        value={fieldVal}
        variant="outlined"
        {...props}
      >
        <option value={''}>{title}</option>
        {options &&
          options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label || option.name}
            </option>
          ))}
      </TextField>
    </Grid>
  );
};

CommonSelector.propTypes = {
  md: PropTypes.number,
  xs: PropTypes.number
};

CommonSelector.defaultProps = {
  md: 6,
  xs: 12
};
