import { call, put } from 'redux-saga/effects';
import {
  REQUEST_ARTS_LIST_SUCCESS,
  REQUEST_ARTS_LIST_ERROR,
  REQUEST_ADD_ARTS_SUCCESS,
  REQUEST_ADD_ARTS_ERROR,
  REQUEST_UPDATE_ART_SUCCESS,
  REQUEST_UPDATE_ART_ERROR,
  REQUEST_DELETE_ART_SUCCESS,
  REQUEST_DELETE_ART_ERROR,
  REQUEST_UPLOAD_ART_IMAGE_SUCCESS,
  REQUEST_UPLOAD_ART_IMAGE_ERROR,
  REQUEST_IMPORT_ART_SUCCESS,
  REQUEST_IMPORT_ART_ERROR,
  REQUEST_EXPORT_ART_SUCCESS,
  REQUEST_EXPORT_ART_ERROR
} from '../actions/artsActions';
import {
  addArts,
  deleteArt,
  exportArts,
  getArtsList,
  importArts,
  updateArt,
  uploadArtImage
} from '../Api';

export function* requestArtsListSaga(action) {
  try {
    const res = yield call(getArtsList, action.pageSize, action.pageNumber);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_ARTS_LIST_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_ARTS_LIST_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_ARTS_LIST_ERROR, error });
    action.callBack(error);
  }
}

export function* requestAddArtsSaga(action) {
  try {
    const res = yield call(addArts, action.data);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_ADD_ARTS_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_ADD_ARTS_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_ADD_ARTS_ERROR, error });
    action.callBack(error);
  }
}

export function* requestUploadArtImageSaga(action) {
  try {
    const res = yield call(uploadArtImage, action.data);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_UPLOAD_ART_IMAGE_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_UPLOAD_ART_IMAGE_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_UPLOAD_ART_IMAGE_ERROR, error });
    action.callBack(error);
  }
}

export function* requestUpdateArtSaga(action) {
  try {
    const res = yield call(updateArt, action.data);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_UPDATE_ART_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_UPDATE_ART_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_UPDATE_ART_ERROR, error });
    action.callBack(error);
  }
}

export function* requestDeleteArtSaga(action) {
  try {
    const res = yield call(deleteArt, action.data);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_DELETE_ART_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_DELETE_ART_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_DELETE_ART_ERROR, error });
    action.callBack(error);
  }
}

export function* requestImportArtSaga(action) {
  try {
    const res = yield call(importArts, action.data);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_IMPORT_ART_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_IMPORT_ART_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_IMPORT_ART_ERROR, error });
    action.callBack(error);
  }
}

export function* requestExportArtSaga(action) {
  try {
    const res = yield call(exportArts);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_EXPORT_ART_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_EXPORT_ART_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_EXPORT_ART_ERROR, error });
    action.callBack(error);
  }
}
