import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import axios from 'axios';
import { BASE_URL } from './constants/api';

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
const token = localStorage.getItem('isAuthenticate');

if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      localStorage.removeItem('isAuthenticate');
      localStorage.removeItem('userRole');
      window.location.replace('/');
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const notistackRef = React.createRef();

const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <BrowserRouter>
    <SnackbarProvider
      ref={notistackRef}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      action={key => (
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
      )}
      maxSnack={1}
    >
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
