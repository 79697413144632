import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { CommonTextField } from 'src/components/CommonTextField';
import { CommonButton } from 'src/components/CommonButton';
import { useNavigate, useLocation } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { REQUEST_CHANGE_PASSWORD } from 'src/redux/actions/loginActions';
import idx from 'idx';

const useStyles = makeStyles(theme => ({
  root: {},
  addbtnStyle: {
    paddingRight: '35px',
    paddingLeft: '35px'
  },
  cancelbtnStyle: {
    paddingRight: '35px',
    paddingLeft: '35px',
    backgroundColor: 'white',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#dfdfdf'
    }
  }
}));

const ChangePasswordForm = props => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const PasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Please enter password'),
    newPassword: Yup.string().required('Please enter new password'),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        'Does not match with entered password!'
      )
      .required('Please enter confirm password')
  });

  const {
    className,
    handleAddUpdate,
    userResponse,
    stateList,
    genderList,
    ...rest
  } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, [state]);

  const handleChange = event => {
    setErrors({ ...errors, [event.target.name]: '' });
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangePassword = () => {
    const { changePassword, enqueueSnackbar } = props;
    const obj = { ...values };
    PasswordSchema.validate(obj, { abortEarly: false })
      .then(() => {
        const data = {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword
        };

        changePassword(data, res => {
          if (res.status === 200) {
            const successMessage =
              idx(res, _ => _.data) || 'Password change successfully';
            enqueueSnackbar(successMessage, {
              variant: 'success'
            });
            localStorage.removeItem('isAuthenticate');
            localStorage.removeItem('userRole');
            navigate('/login', { replace: true });
          } else {
            const errorMessage = idx(res, _ => _.message) || 'An error occured';
            enqueueSnackbar(errorMessage, { variant: 'error' });
          }
        });
      })
      .catch(e => {
        let errorObj = {};
        e.inner.forEach(el => {
          errorObj = {
            ...errorObj,
            [el.path]: el.message
          };
        });
        setErrors(errorObj);
      });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <CommonTextField
              name={'Old Password'}
              value={'oldPassword'}
              onChange={e => handleChange(e)}
              md={12}
              error={errors['oldPassword']}
              fieldVal={values['oldPassword']}
              type="password"
            />
            <CommonTextField
              name={'New Password'}
              value={'newPassword'}
              onChange={e => handleChange(e)}
              md={12}
              error={errors['newPassword']}
              fieldVal={values['newPassword']}
              type="password"
            />
            <CommonTextField
              name={'Confirm Password'}
              value={'confirmPassword'}
              onChange={e => handleChange(e)}
              md={12}
              error={errors['confirmPassword']}
              fieldVal={values['confirmPassword']}
              type="password"
            />
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <CommonButton
            themeColor={''}
            style={{ width: '130px' }}
            className={classes.cancelbtnStyle}
            title={'Cancel'}
            onClick={() => {
              navigate('/', { replace: true });
            }}
          />
          <CommonButton
            style={{ width: '130px', marginLeft: '20px' }}
            className={classes.addbtnStyle}
            title={'Change'}
            isLoading={props.changePasswordIsInProgress}
            onClick={handleChangePassword}
          />
        </Box>
      </Card>
    </form>
  );
};

ChangePasswordForm.propTypes = {
  className: PropTypes.string,
  userdetails: PropTypes.object
};

const mapStateToProps = state => {
  const { loginReducer } = state;
  const {
    changePasswordIsInProgress,
    changePasswordResponse,
    changePasswordError
  } = loginReducer;
  return {
    changePasswordIsInProgress,
    changePasswordResponse,
    changePasswordError
  };
};

const mapDispatchToProps = dispatch => ({
  changePassword: (data, callBack) =>
    dispatch({ type: REQUEST_CHANGE_PASSWORD, data, callBack })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ChangePasswordForm));
