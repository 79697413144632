import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, makeStyles } from '@material-ui/core';
import NavItem from './NavItem';
import { connect } from 'react-redux';

import {
  ADMIN_DRAWER_ITEMS,
  SUPER_ADMIN_DRAWER_ITEMS
} from 'src/constants/appConst';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    borderRightColor: '#dfdfdf',
    borderTopColor: '#dfdfdf',
    borderTop: '1.2px solid'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  name: {
    paddingTop: '10px'
  }
}));

const NavBar = props => {
  const { onMobileClose, openMobile, userResponse } = props;

  const classes = useStyles();
  const location = useLocation();
  const role = localStorage.getItem('userRole');
  const DRAWER_ITEMS =
    role === 'superadmin' ? SUPER_ADMIN_DRAWER_ITEMS : ADMIN_DRAWER_ITEMS;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box flexDirection="column" display="flex" p={2}>
        <List>
          {DRAWER_ITEMS.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              onClick={item.onClick}
              activePath={location.pathname}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

const mapStateToProps = state => {
  const { loginReducer } = state;
  const { loginResponse } = loginReducer;
  return { loginResponse };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
