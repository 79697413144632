import { strings } from 'src/utils/strings';
import {config} from "../environments/environment";

export const BASE_URL = config.BASE_URL;
export const ADD_USER = `${BASE_URL}/user/addUser`;
export const UPDATE_USER = `${BASE_URL}/user/updateUser`;
export const DELETE_USER = `${BASE_URL}/user/deleteUser`;
export const GET_USERS = `${BASE_URL}/user/listUsers`;
export const LOGIN = `${BASE_URL}/login`;
export const GET_ARTS = `${BASE_URL}/admin/listArts`;
export const ADD_ART = `${BASE_URL}/admin/addArt/`;
export const UPLOAD_ART_IMAGE = `${BASE_URL}/admin/uploadFiles`;
export const UPDATE_ART = `${BASE_URL}/admin/updateArt`;
export const DELETE_ART = `${BASE_URL}/admin/deleteArt`;
export const CHANGE_PASSWORD = `${BASE_URL}/user/changePassword`;
export const BULK_IMPORT_USER = `${BASE_URL}/user/bulkImportUsers`;
export const BULK_EXPORT_USER = `${BASE_URL}/user/bulkExportUsers`;
export const BULK_IMPORT_ART = `${BASE_URL}/admin/bulkImportArts`;
export const BULK_EXPORT_ART = `${BASE_URL}/admin/bulkExportArts`;

export const DEFAULT_ERROR_RESPONSE = {
  status: 500,
  error: 'common_error',
  error_description: strings.wentWrong
};
