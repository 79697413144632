import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import NotFoundView from 'src/container/errors/NotFoundView';
import ListIndex from './container/Arts/ListIndex';
import UserListIndex from './container/Users/ListIndex';
import Arts from './container/Arts';
import Users from './container/Users';
import ChangePassword from './container/ChangePassword';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'arts', element: <ListIndex /> },
      { path: 'addArt', element: <Arts /> },
      { path: 'arts/:artId', element: <Arts /> },
      { path: 'updateArt/:artId', element: <Arts /> },
      { path: 'users', element: <UserListIndex /> },
      { path: 'addUser', element: <Users /> },
      { path: 'users/:userId', element: <Users /> },
      { path: 'updateUser/:userId', element: <Users /> },
      { path: 'changePassword', element: <ChangePassword /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <ListIndex /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
