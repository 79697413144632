import { combineReducers } from 'redux';
import userReducer from './userReducer';
import loginReducer from './loginReducer';
import commonReducer from './commonReducer';
import artsReducer from './artsReducer';

const rootReducer = combineReducers({
  userReducer,
  loginReducer,
  commonReducer,
  artsReducer
});

export default rootReducer;
