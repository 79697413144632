import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  makeStyles,
  IconButton,
  Grid,
  Typography,
  OutlinedInput,
  Box,
  CircularProgress
} from '@material-ui/core';
import { stableSort, getComparator } from 'src/utils/sortingUtils';
import { connect } from 'react-redux';
import { ARTS_HEAD_CELLS } from 'src/constants/appConst';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import { strings } from 'src/utils/strings';
import { EnhancedTableHead } from 'src/components/TableHead';
import {
  REQUEST_ARTS_LIST,
  REQUEST_DELETE_ART
} from 'src/redux/actions/artsActions';
import { Loader } from 'src/components/Loader';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { useNavigate } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import idx from 'idx';
import './art.css';
import { ConfirmationModal } from 'src/components/ConfirmationModal';
import { makeFirstLetterCapital } from 'src/constants/utils';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 25,
    border: '2px solid',
    padding: '3px',
    color: theme.palette.primary.main
  },
  search: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius
  },
  loaderWrapper: {
    marginTop: '1rem'
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  deleteModalIconStyle: {
    color: theme.palette.primary.main,
    marginTop: 15,
    marginBottom: 15
  },
  padding: {
    paddingLeft: 15,
    paddingRight: 15
  },
  paddingVertical: {
    paddingTop: 15,
    paddingBottom: 15
  },
  titleParentView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15
  },
  titleChildView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  modalButtonParentView: {
    paddingBottom: 15
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontFamily: 'Roboto'
  }
}));

const ArtList = props => {
  const {
    className,
    artsList,
    rest,
    artsListIsInProgress,
    artsListError,
    appliedFilter
  } = props;
  const [arts, setArts] = useState([]);
  const navigate = useNavigate();
  const [errorData, setErrorData] = useState(null);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('artName');
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchText, setSearchText] = React.useState('');
  const [appliedFilters, setAppliedFilters] = React.useState([]);
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [totalArtData, setTotalArtData] = React.useState(0);

  const callGetArtList = (pageNumber, pageSize) => {
    const { getArtsList } = props;
    getArtsList(
      pageSize ? pageSize : rowsPerPage,
      pageNumber >= 0 ? pageNumber + 1 : page + 1,
      res => {
        if (res.status === 200) {
          const artList = idx(res, _ => _.data.arts) || [];
          const totalArt = idx(res, _ => _.data.total_records) || 0;
          setTotalArtData(totalArt);
          setArts(artList);
        } else if (artsListError) {
          setErrorData(artsListError);
        }
      }
    );
  };

  useEffect(() => {
    callGetArtList();
  }, []);

  useEffect(() => {
    const { artsList } = props;
    const artListData = idx(artsList, _ => _.data.arts) || [];
    const totalArt = idx(artsList, _ => _.data.total_records) || 0;
    setTotalArtData(totalArt);
    setArts(artListData);
  }, [props.artsList]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePageChange = (event, newPage) => {
    callGetArtList(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    callGetArtList(0, parseInt(event.target.value, 10));
  };

  const handleSearch = val => {
    setSearchText(val.target.value);
    let filteredData = [];
    const artsData = idx(artsList, _ => _.data.arts) || [];
    filteredData = artsData.filter(
      e =>
        e.artName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        e.description.toLowerCase().includes(val.target.value.toLowerCase()) ||
        e.address.toLowerCase().includes(val.target.value.toLowerCase()) ||
        e.artType.toLowerCase().includes(val.target.value.toLowerCase())
    );
    setArts(filteredData);
  };

  const deleteArtData = (row, index) => {
    try {
      setDeleteInProgress(index);
      props.deleteArt(row, res => {
        if (res.status === 200) {
          const successMessage =
            idx(res, _ => _.data) || 'Art deleted successfully';
          setDeleteInProgress(false);
          props.enqueueSnackbar(successMessage, {
            variant: 'success'
          });
          callGetArtList();
          setPage(0);
          setShowConfirmation(false);
        } else {
          const errorMessage = idx(res, _ => _.message) || 'An error occured';
          props.enqueueSnackbar(errorMessage, {
            variant: 'error'
          });
          setDeleteInProgress(false);
          setShowConfirmation(false);
        }
      });
    } catch (e) {
      const errorMessage = 'An error occured';
      props.enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
      setDeleteInProgress(false);
      setShowConfirmation(false);
    }
  };

  const isStableData =
    stableSort(arts, getComparator(order, orderBy)) &&
    stableSort(arts, getComparator(order, orderBy)).length > 0
      ? true
      : false;
  return (
    <Card className={clsx(className)} {...rest}>
      <PerfectScrollbar>
        <Grid container>
          <Grid alignItems="center" justify={'flex-end'} container>
            <Grid item lg={3} md={5} sm={6} xs={10} className={classes.search}>
              <OutlinedInput
                style={{ width: '100%' }}
                margin={'dense'}
                placeholder="Search…"
                value={searchText}
                onChange={handleSearch}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: 'black' }} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        if (searchText !== '') {
                          const artData = idx(artsList, _ => _.data.arts) || [];
                          setSearchText('');
                          setArts(artData);
                        }
                      }}
                    >
                      <CloseIcon style={{ color: 'black' }} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table style={{ overflowX: 'scroll' }}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={ARTS_HEAD_CELLS}
              />
              {!artsListIsInProgress && isStableData ? (
                <TableBody>
                  {stableSort(arts, getComparator(order, orderBy)).map(
                    (row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell></TableCell>
                          <TableCell component="th" scope="row">
                            <Typography className={'description-text'}>
                              {row.artName || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={'description-text'}>
                              {row.artType
                                ? makeFirstLetterCapital(row.artType)
                                : '-'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={'description-text'}>
                              {row.address}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={'description-text'}>
                              {row.description}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <div className={classes.actionRow}>
                              <IconButton
                                onClick={() => {
                                  navigate(`/app/arts/${row._id}`, {
                                    state: {
                                      isViewMode: true,
                                      ...row
                                    }
                                  });
                                }}
                                className={classes.root}
                                style={{
                                  marginRight: '1rem'
                                }}
                              >
                                <VisibilityIcon
                                  style={{
                                    fontSize: '20px'
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  navigate(`/app/updateArt/${row._id}`, {
                                    state: { ...row }
                                  });
                                }}
                                style={{
                                  marginRight: '1rem'
                                }}
                                className={classes.root}
                              >
                                <EditIcon
                                  style={{
                                    fontSize: '20px'
                                  }}
                                />
                              </IconButton>

                              <IconButton
                                onClick={() => {
                                  setSelectedIndex(index);
                                  setSelectedRow(row);
                                  setShowConfirmation(true);
                                }}
                                className={classes.root}
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: '20px'
                                  }}
                                />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              ) : null}
            </Table>
            {artsListIsInProgress ? (
              <Loader loaderWrapper={classes.loaderWrapper} />
            ) : null}
            {!artsListIsInProgress && !isStableData ? (
              <Box
                color={'#e0e0e0'}
                style={{
                  paddingTop: 15,
                  paddingBottom: 15
                }}
              >
                <Grid style={{ display: 'flex' }} justify={'center'} container>
                  <Typography style={{ color: 'black' }}>
                    {strings.noArtText}
                  </Typography>
                </Grid>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalArtData}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 75]}
      />
      {showConfirmation ? (
        <ConfirmationModal
          open={showConfirmation}
          title={'Delete Art'}
          subTitle={'Are you sure you want to delete this art?'}
          classes={classes}
          onClose={() => setShowConfirmation(false)}
          onConfirmation={() => deleteArtData(selectedRow, selectedIndex)}
          isConfirmationInProgress={deleteInProgress}
          icon={() => <DeleteIcon className={classes.deleteModalIconStyle} />}
          wrapperClass={classes.paddingVertical}
          buttonWrapper={classes.modalButtonParentView}
          titleWrapper={classes.modalTitle}
          fullWidth
        />
      ) : null}
    </Card>
  );
};

ArtList.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  const { artsReducer } = state;
  const {
    artsList,
    artsListIsInProgress,
    artsListError,
    deleteArtIsInProgress,
    deleteArtError
  } = artsReducer;
  return {
    artsList,
    artsListIsInProgress,
    artsListError,
    deleteArtIsInProgress,
    deleteArtError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getArtsList: (pageSize, pageNumber, callBack) =>
      dispatch({ type: REQUEST_ARTS_LIST, pageSize, pageNumber, callBack }),
    deleteArt: (data, callBack) =>
      dispatch({ type: REQUEST_DELETE_ART, data, callBack })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ArtList));
