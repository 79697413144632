export const REQUEST_ADD_USER = 'REQUEST_ADD_USER';
export const REQUEST_ADD_USER_SUCCESS = 'REQUEST_ADD_USER_SUCCESS';
export const REQUEST_ADD_USER_ERROR = 'REQUEST_ADD_USER_ERROR';

export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER';
export const REQUEST_UPDATE_USER_SUCCESS = 'REQUEST_UPDATE_USER_SUCCESS';
export const REQUEST_UPDATE_USER_ERROR = 'REQUEST_UPDATE_USER_ERROR';

export const REQUEST_DELETE_USER = 'REQUEST_DELETE_USER';
export const REQUEST_DELETE_USER_SUCCESS = 'REQUEST_DELETE_USER_SUCCESS';
export const REQUEST_DELETE_USER_ERROR = 'REQUEST_DELETE_USER_ERROR';

export const REQUEST_GET_USERS_LIST = 'REQUEST_GET_USERS_LIST';
export const REQUEST_GET_USERS_LIST_SUCCESS = 'REQUEST_GET_USERS_LIST_SUCCESS';
export const REQUEST_GET_USERS_LIST_ERROR = 'REQUEST_GET_USERS_LIST_ERROR';

export const REQUEST_IMPORT_USER = 'REQUEST_IMPORT_USER';
export const REQUEST_IMPORT_USER_SUCCESS = 'REQUEST_IMPORT_USER_SUCCESS';
export const REQUEST_IMPORT_USER_ERROR = 'REQUEST_IMPORT_USER_ERROR';

export const REQUEST_EXPORT_USER = 'REQUEST_EXPORT_USER';
export const REQUEST_EXPORT_USER_SUCCESS = 'REQUEST_EXPORT_USER_SUCCESS';
export const REQUEST_EXPORT_USER_ERROR = 'REQUEST_EXPORT_USER_ERROR';
