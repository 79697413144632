import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
  makeStyles,
  Typography,
  Grid,
  FormControl,
  InputAdornment
} from '@material-ui/core';
import theme from 'src/theme';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  padding10x: {
    padding: '6px'
  },
  label: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: '5px'
    },
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 18
  },
  labelGrid: {
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    },
    marginRight: '10px'
  }
}));

export const CommonInput = props => {
  const classes = useStyles();

  const {
    title,
    value,
    isMultiline,
    rows,
    style,
    onChange,
    inputId,
    type,
    isPrice,
    error,
    placeholder,
    inputWrapper
  } = props;

  return (
    <Grid
      className={clsx(classes.padding10x, inputWrapper)}
      direction={'row'}
      container
      item
      display={'flex'}
    >
      {title ? (
        <Grid item md={4} xs={12}>
          <Typography className={classes.label} color="textPrimary">
            {title}
          </Typography>
        </Grid>
      ) : null}
      <Grid item lg={title ? 6 : 12} md={12} xs={12}>
        <TextField
          error={error}
          helperText={error}
          variant="outlined"
          placeholder={placeholder || ''}
          margin="dense"
          id={inputId}
          value={value}
          type={type}
          onChange={onChange}
          style={{ display: 'flex' }}
          multiline={isMultiline}
          rows={rows}
          InputProps={{
            startAdornment: isPrice ? (
              <InputAdornment position="start">
                <Typography color="textPrimary">$</Typography>
              </InputAdornment>
            ) : null
          }}
        />
      </Grid>
    </Grid>
  );
};
