import React from 'react';

import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

export const CommonTextField = props => {
  const {
    name,
    value,
    type,
    onChange,
    fieldVal,
    error,
    md,
    xs,
    disabled,
    className,
    inputRequired
  } = props;
  return (
    <Grid
      style={{ pointerEvents: disabled ? 'none' : 'all' }}
      item
      md={md}
      xs={xs}
    >
      <TextField
        className={className}
        error={error}
        helperText={error}
        fullWidth
        label={name}
        name={value}
        type={type}
        onChange={onChange}
        required={inputRequired !== undefined ? inputRequired : true}
        value={fieldVal}
        variant="outlined"
      />
    </Grid>
  );
};

CommonTextField.propTypes = {
  md: PropTypes.number,
  xs: PropTypes.number,
  required: PropTypes.bool
};

CommonTextField.defaultProps = {
  md: 6,
  xs: 12,
  required: true
};
