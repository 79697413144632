import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Divider
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { CommonButton } from './CommonButton';
import clsx from 'clsx';

export const ConfirmationModal = props => {
  const {
    fullWidth,
    title,
    subTitle,
    onClose,
    onConfirmation,
    cancelButtonText,
    doneButtonText,
    open,
    icon,
    closeIcon,
    isConfirmationInProgress,
    wrapperClass,
    classes,
    titleWrapper,
    subTitleWrapper,
    buttonWrapper,
    titleParentWrapper
  } = props;
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (onClose) onClose();
      }}
      aria-labelledby="form-dialog-title"
      fullWidth={fullWidth}
      className={wrapperClass}
    >
      <div className={clsx(classes.titleParentView, titleParentWrapper)}>
        <div className={classes.titleChildView}>
          {icon ? icon() : null}
          <Typography className={clsx(classes.padding, titleWrapper)}>
            {title}
          </Typography>
        </div>
        {closeIcon ? closeIcon() : null}
      </div>
      <Divider />
      <DialogContent
        id="form-dialog-title"
        className={clsx(classes.padding, subTitleWrapper)}
      >
        <Typography style={{ fontSize: 17 }}>{subTitle}</Typography>
      </DialogContent>
      <DialogActions className={clsx(classes.padding, buttonWrapper)}>
        <CommonButton
          themeColor={''}
          title={cancelButtonText}
          onClick={onClose}
        />
        <CommonButton
          title={doneButtonText}
          isLoading={isConfirmationInProgress}
          onClick={onConfirmation}
        />
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  fullWidth: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onCloseModal: PropTypes.func,
  onConfirmation: PropTypes.func,
  cancelButtonText: PropTypes.string,
  doneButtonText: PropTypes.string,
  open: PropTypes.bool,
  icon: PropTypes.any,
  closeIcon: PropTypes.any,
  isConfirmationInProgress: PropTypes.bool,
  wrapperClass: PropTypes.object,
  classes: PropTypes.object,
  titleWrapper: PropTypes.object,
  subTitleWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  titleParentWrapper: PropTypes.object
};

ConfirmationModal.defaultProps = {
  fullWidth: false,
  title: '',
  subTitle: '',
  onCloseModal: () => {},
  onConfirmation: () => {},
  cancelButtonText: 'No',
  doneButtonText: 'Yes',
  open: false,
  icon: null,
  closeIcon: null,
  isConfirmationInProgress: false,
  wrapperClass: {},
  classes: {},
  titleWrapper: {},
  subTitleWrapper: {},
  buttonWrapper: {},
  titleParentWrapper: {}
};
