import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import store from './redux/store';
import loginRoutes from './loginRoutes';
import Geocode from 'react-geocode';
import {
  GOOGLE_API_KEY,
  GOOGLE_REGION,
  GOOGLE_LOCATION_TYPE
} from './constants/appConst';

const App = () => {
  const routing = useRoutes(routes);
  const loginRoute = useRoutes(loginRoutes);
  const isAuthenticated = localStorage.getItem('isAuthenticate');
  Geocode.setApiKey(GOOGLE_API_KEY);
  Geocode.setLanguage(GOOGLE_REGION);
  Geocode.setLocationType(GOOGLE_LOCATION_TYPE);
  Geocode.enableDebug();
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {!isAuthenticated ? loginRoute : routing}
      </ThemeProvider>
    </Provider>
  );
};

export default App;
