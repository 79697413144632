import {
  REQUEST_ALL_GENDER_LIST_SUCCESS,
  REQUEST_ALL_STATE_LIST_SUCCESS
} from '../actions/commonActions';

const initialState = {
  stateList: [],
  genderList: []
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ALL_STATE_LIST_SUCCESS:
      const formatedStates = action.data.map(el => {
        let obj = {
          label: el.state,
          value: el.state
        };
        return obj;
      });
      return {
        ...state,
        stateList: formatedStates
      };
    case REQUEST_ALL_GENDER_LIST_SUCCESS:
      return {
        ...state,
        genderList: action.data
      };
    default:
      return state;
  }
};

export default commonReducer;
