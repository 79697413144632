import { Users, PenTool } from 'react-feather';

export const filter_By = [
  { name: 'Type', value: 'artType' },
  { name: 'Name', value: 'artName' },
  { name: 'Location', value: 'address' }
];

export const artTypeList = [
  { id: 0, name: 'Sculpture', value: 'Sculpture' },
  { id: 1, name: 'Mural', value: 'Mural' },
  { id: 2, name: 'Gallery', value: 'Gallery' }
];

export const appName = 'Art stumble';

export const NAV_DRAWER_COLOR = '#DEC9BC';
export const FILE_UPLOAD_LIMIT = 6;
export const AUTH_TOKEN = localStorage.getItem('isAuthenticate');
export const GOOGLE_API_KEY = 'AIzaSyDJdPSWr2BziwdnpRDs7K7KcpR7Zrfz8vQ';
export const GOOGLE_REGION = 'en';
export const GOOGLE_LOCATION_TYPE = 'ROOFTOP';

export const ARTS_HEAD_CELLS = [
  { id: 'artName', numeric: true, disablePadding: false, label: 'Art Name' },
  { id: 'artType', numeric: true, disablePadding: false, label: 'Art Type' },
  {
    id: 'address',
    numeric: true,
    disablePadding: false,
    label: 'Location',
    width: 450
  },
  {
    id: 'description',
    numeric: true,
    disablePadding: false,
    label: 'Description'
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: 'Action',
    hideSort: true
  }
];

export const USERS_HEAD_CELLS = [
  { id: 'userName', numeric: true, disablePadding: false, label: 'Full Name' },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
  {
    id: 'isAdmin',
    numeric: true,
    disablePadding: false,
    label: 'Is SuperAdmin',
    hideSort: true
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: 'Action',
    hideSort: true
  }
];

export const SUPER_ADMIN_DRAWER_ITEMS = [
  {
    href: '/app/arts',
    icon: PenTool,
    title: 'Arts'
  },
  {
    href: '/app/users',
    icon: Users,
    title: 'Users'
  }
];

export const ADMIN_DRAWER_ITEMS = [
  {
    href: '/app/arts',
    icon: PenTool,
    title: 'Arts'
  }
];

export const stateListData = [
  {
    id: 0,
    value: 'Uttar Pradesh',
    name: 'Uttar Pradesh'
  },
  {
    id: 1,
    value: 'Gujarat',
    name: 'Gujarat'
  },
  {
    id: 2,
    value: 'Maharastra',
    name: 'Maharastra'
  },
  {
    id: 3,
    value: 'Bihar',
    name: 'Bihar'
  },
  {
    id: 4,
    value: 'Madhya Pradesh',
    name: 'Madhya Pradesh'
  },
  {
    id: 5,
    value: 'Tamil Nadu',
    name: 'Tamil Nadu'
  },
  {
    id: 6,
    value: 'Rajasthan',
    name: 'Rajasthan'
  },
  {
    id: 7,
    value: 'Karnataka',
    name: 'Karnataka'
  },
  {
    id: 8,
    value: 'Andhra Pradesh',
    name: 'Andhra Pradesh'
  },
  {
    id: 9,
    value: 'Odisha',
    name: 'Odisha'
  },
  {
    id: 10,
    value: 'Telangana',
    name: 'Telangana'
  },
  {
    id: 11,
    value: 'Kerala',
    name: 'Kerala'
  },
  {
    id: 12,
    value: 'Jharkhand',
    name: 'Jharkhand'
  },
  {
    id: 13,
    value: 'Assam',
    name: 'Assam'
  },
  {
    id: 14,
    value: 'Punjab',
    name: 'Punjab'
  },
  {
    id: 15,
    value: 'Chhattisgarh',
    name: 'Chhattisgarh'
  },
  {
    id: 16,
    value: 'Haryana',
    name: 'Haryana'
  }
];
