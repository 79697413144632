import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  makeStyles,
  IconButton,
  Grid,
  Typography,
  OutlinedInput,
  CircularProgress
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { stableSort, getComparator } from 'src/utils/sortingUtils';
import { connect } from 'react-redux';
import { USERS_HEAD_CELLS } from 'src/constants/appConst';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import { strings } from 'src/utils/strings';
import { EnhancedTableHead } from 'src/components/TableHead';
import {
  REQUEST_DELETE_USER,
  REQUEST_GET_USERS_LIST
} from 'src/redux/actions/userActions';
import { Loader } from 'src/components/Loader';
import _ from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import idx from 'idx';
import './user.css';
import { ConfirmationModal } from 'src/components/ConfirmationModal';
const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 25,
    border: '2px solid',
    padding: '3px',
    color: theme.palette.primary.main
  },
  search: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius
  },
  loaderWrapper: {
    marginTop: '1rem'
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  deleteModalIconStyle: {
    color: theme.palette.primary.main,
    marginTop: 15,
    marginBottom: 15
  },
  padding: {
    paddingLeft: 15,
    paddingRight: 15
  },
  paddingVertical: {
    paddingTop: 15,
    paddingBottom: 15
  },
  titleParentView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15
  },
  titleChildView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  modalButtonParentView: {
    paddingBottom: 15
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontFamily: 'Roboto'
  }
}));

const UserList = props => {
  const navigate = useNavigate();
  const { className, rest } = props;
  const [usersData, setUsersData] = useState([]);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchText, setSearchText] = React.useState('');
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [usersListIsInProgress, setUsersListIsInProgress] = React.useState(
    true
  );
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [totalUser, setTotalUser] = React.useState(0);

  useEffect(() => {
    callGetUserList();
  }, []);

  useEffect(() => {
    const { usersList } = props;
    const userListData = idx(usersList, _ => _.users) || [];
    const totalUserData = idx(usersList, _ => _.total_records) || 0;
    setUsersData(userListData);
    setTotalUser(totalUserData);
  }, [props.usersList]);

  const callGetUserList = (pageNumber, pageSize) => {
    const { getUsersList } = props;
    getUsersList(
      pageSize ? pageSize : rowsPerPage,
      pageNumber >= 0 ? pageNumber + 1 : page + 1,
      () => {
        setUsersListIsInProgress(false);
      }
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    callGetUserList(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    callGetUserList(0, parseInt(event.target.value, 10));
  };

  const handleSearch = val => {
    const { usersList } = props;
    setSearchText(val.target.value);
    let filteredData = [];
    const userListData = idx(usersList, _ => _.users) || [];
    filteredData = userListData.filter(
      e =>
        e.userName.toLowerCase().includes(val.target.value.toLowerCase()) ||
        e.email.toLowerCase().includes(val.target.value.toLowerCase())
    );
    setUsersData(filteredData);
  };

  const deleteUser = (row, index) => {
    const { deleteUser, enqueueSnackbar } = props;
    setDeleteInProgress(index);
    deleteUser(row, res => {
      if (res.status === 200) {
        enqueueSnackbar('User deleted successfully', {
          variant: 'success'
        });
        setDeleteInProgress(false);
        setShowConfirmation(false);
        callGetUserList();
        setPage(0);
      } else {
        enqueueSnackbar(res.message, {
          variant: 'error'
        });
        setDeleteInProgress(false);
        setShowConfirmation(false);
      }
    });
  };

  const isStableData =
    stableSort(usersData, getComparator(order, orderBy)) &&
    stableSort(usersData, getComparator(order, orderBy)).length > 0
      ? true
      : false;
  return (
    <Card className={clsx(className)} {...rest}>
      <PerfectScrollbar>
        <Grid container>
          <Grid alignItems="center" justify={'flex-end'} container>
            <Grid item lg={3} md={5} sm={6} xs={10} className={classes.search}>
              <OutlinedInput
                style={{ width: '100%' }}
                margin={'dense'}
                placeholder="Search…"
                value={searchText}
                onChange={handleSearch}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: 'black' }} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        if (searchText !== '') {
                          const userData =
                            idx(props, _ => _.usersList.users) || [];
                          setSearchText('');
                          setUsersData(userData);
                        }
                      }}
                    >
                      <CloseIcon style={{ color: 'black' }} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              style={{
                overflowX: 'scroll'
              }}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={usersData.length}
                headCells={USERS_HEAD_CELLS}
              />
              {!usersListIsInProgress && isStableData ? (
                <TableBody>
                  {stableSort(usersData, getComparator(order, orderBy)).map(
                    (row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography className={'description-text'}>
                              {row.userName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={'description-text'}>
                              {row.email}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {row.role === 'superadmin' ? 'Yes' : 'No'}
                          </TableCell>

                          <TableCell>
                            <div className={classes.actionRow}>
                              <IconButton
                                onClick={() => {
                                  navigate(`/app/users/${row._id}`, {
                                    state: { isViewMode: true, ...row }
                                  });
                                }}
                                className={classes.root}
                                style={{ marginRight: '1rem' }}
                              >
                                <VisibilityIcon style={{ fontSize: '20px' }} />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  navigate(`/app/updateUser/${row._id}`, {
                                    state: { ...row }
                                  });
                                }}
                                style={{ marginRight: '1rem' }}
                                className={classes.root}
                              >
                                <EditIcon style={{ fontSize: '20px' }} />
                              </IconButton>

                              <IconButton
                                onClick={() => {
                                  setSelectedRow(row);
                                  setShowConfirmation(true);
                                  setSelectedIndex(index);
                                }}
                                className={classes.root}
                              >
                                <DeleteIcon style={{ fontSize: '20px' }} />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              ) : null}
            </Table>
            {usersListIsInProgress ? (
              <Loader loaderWrapper={classes.loaderWrapper} />
            ) : null}
            {!usersListIsInProgress && !isStableData ? (
              <Grid
                style={{
                  display: 'flex',
                  paddingTop: 15,
                  paddingBottom: 15
                }}
                justify={'center'}
                container
              >
                <Typography style={{ color: 'black' }}>
                  {strings.noUsersText}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalUser}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 75]}
        page={page}
      />
      {showConfirmation ? (
        <ConfirmationModal
          open={showConfirmation}
          title={'Delete User'}
          subTitle={'Are you sure you want to delete this user?'}
          classes={classes}
          onClose={() => setShowConfirmation(false)}
          onConfirmation={() => deleteUser(selectedRow, selectedIndex)}
          isConfirmationInProgress={deleteInProgress}
          icon={() => <DeleteIcon className={classes.deleteModalIconStyle} />}
          wrapperClass={classes.paddingVertical}
          buttonWrapper={classes.modalButtonParentView}
          titleWrapper={classes.modalTitle}
          fullWidth
        />
      ) : null}
    </Card>
  );
};

UserList.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  const { userReducer } = state;
  const { usersList } = userReducer;
  return {
    usersList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUsersList: (pageSize, pageNumber, callBack) =>
      dispatch({
        type: REQUEST_GET_USERS_LIST,
        pageSize,
        pageNumber,
        callBack
      }),
    deleteUser: (data, callBack) =>
      dispatch({ type: REQUEST_DELETE_USER, data, callBack })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(UserList));
