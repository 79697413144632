import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}));

export const Loader = props => {
  const classes = useStyles();
  const { className, loaderWrapper, color, thickness } = props;
  return (
    <div className={clsx(classes.root, loaderWrapper)}>
      <CircularProgress
        classes={className}
        color={color}
        thickness={thickness}
      />
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.object,
  loaderWrapper: PropTypes.object,
  color: PropTypes.string,
  thickness: PropTypes.number
};

Loader.defaultProps = {
  className: {},
  loaderWrapper: {},
  color: 'primary',
  thickness: 3.6
};
