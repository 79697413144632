export const REQUEST_ARTS_LIST = 'REQUEST_ARTS_LIST';
export const REQUEST_ARTS_LIST_SUCCESS = 'REQUEST_ARTS_LIST_SUCCESS';
export const REQUEST_ARTS_LIST_ERROR = 'REQUEST_ARTS_LIST_ERROR';

export const REQUEST_ADD_ARTS = 'REQUEST_ADD_ARTS';
export const REQUEST_ADD_ARTS_SUCCESS = 'REQUEST_ADD_ARTS_SUCCESS';
export const REQUEST_ADD_ARTS_ERROR = 'REQUEST_ADD_ARTS_ERROR';

export const REQUEST_UPLOAD_ART_IMAGE = 'REQUEST_UPLOAD_ART_IMAGE';
export const REQUEST_UPLOAD_ART_IMAGE_SUCCESS =
  'REQUEST_UPLOAD_ART_IMAGE_SUCCESS';
export const REQUEST_UPLOAD_ART_IMAGE_ERROR = 'REQUEST_UPLOAD_ART_IMAGE_ERROR';

export const REQUEST_UPDATE_ART = 'REQUEST_UPDATE_ART';
export const REQUEST_UPDATE_ART_SUCCESS = 'REQUEST_UPDATE_ART_SUCCESS';
export const REQUEST_UPDATE_ART_ERROR = 'REQUEST_UPDATE_ART_ERROR';

export const REQUEST_DELETE_ART = 'REQUEST_DELETE_ART';
export const REQUEST_DELETE_ART_SUCCESS = 'REQUEST_DELETE_ART_SUCCESS';
export const REQUEST_DELETE_ART_ERROR = 'REQUEST_DELETE_ART_ERROR';

export const REQUEST_IMPORT_ART = 'REQUEST_IMPORT_ART';
export const REQUEST_IMPORT_ART_SUCCESS = 'REQUEST_IMPORT_ART_SUCCESS';
export const REQUEST_IMPORT_ART_ERROR = 'REQUEST_IMPORT_ART_ERROR';

export const REQUEST_EXPORT_ART = 'REQUEST_EXPORT_ART';
export const REQUEST_EXPORT_ART_SUCCESS = 'REQUEST_EXPORT_ART_SUCCESS';
export const REQUEST_EXPORT_ART_ERROR = 'REQUEST_EXPORT_ART_ERROR';
