import {
  REQUEST_EXPORT_USER_SUCCESS,
  REQUEST_GET_USERS_LIST_SUCCESS,
  REQUEST_IMPORT_USER_SUCCESS
} from '../actions/userActions';

const initialState = {
  usersListIsInProgress: false,
  usersListError: null,
  usersList: [],
  importUserList: [],
  exportUserList: []
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.data.data
      };

    case REQUEST_IMPORT_USER_SUCCESS:
      return {
        ...state,
        importUserList: action.data.data
      };

    case REQUEST_EXPORT_USER_SUCCESS:
      return {
        ...state,
        exportUserList: action.data.data
      };

    default:
      return state;
  }
};

export default userReducer;
