import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  IconButton,
  Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { CommonTextField } from 'src/components/CommonTextField';
import { CommonSelector } from 'src/components/CommonSelector';
import { CommonButton } from 'src/components/CommonButton';
import { useNavigate, useLocation } from 'react-router-dom';
import { artTypeList } from 'src/constants/appConst';
import theme from 'src/theme';
import {
  REQUEST_ADD_ARTS,
  REQUEST_ARTS_LIST,
  REQUEST_UPDATE_ART,
  REQUEST_UPLOAD_ART_IMAGE
} from 'src/redux/actions/artsActions';
import { withSnackbar } from 'notistack';
import idx from 'idx';
import CancelIcon from '@material-ui/icons/Cancel';
import Geocode from 'react-geocode';
import { makeFirstLetterCapital } from 'src/constants/utils';

const useStyles = makeStyles(() => ({
  root: {},
  addbtnStyle: {},
  cancelbtnStyle: {
    paddingRight: '35px',
    paddingLeft: '35px',
    backgroundColor: 'white',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#dfdfdf'
    }
  },
  uploadButton: {
    opacity: 0,
    display: 'none'
  },
  artistTitleText: {
    marginTop: 20
  },
  artistInputParentView: {
    marginTop: 20,
    borderRadius: 4,
    flexDirection: 'column'
  },
  imageView: {
    border: `0.5px solid #c4c4c4`,
    padding: 24,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    borderRadius: 4
  },
  artImageParentView: {
    border: '1px solid #c4c4c4',
    borderRadius: 4,
    marginTop: 20,
    padding: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  closeButtonStyle: {
    position: 'absolute',
    top: -11,
    right: -11,
    padding: 0,
    color: theme.palette.primary.main
  },
  imageChildView: {
    display: 'inline-flex',
    position: 'relative',
    flexDirection: 'column'
  },
  errorTextStyle: {
    color: '#f44336',
    margin: 0,
    fontSize: '0.75rem',
    marginTop: 3,
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.66
  }
}));

const AddArt = props => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const artId = idx(state, _ => _._id) || null;
  const artType = idx(state, _ => _.artType)
    ? makeFirstLetterCapital(state.artType)
    : '';
  const artName = idx(state, _ => _.artName) || '';
  const artDesc = idx(state, _ => _.description) || '';
  const artLink = idx(state, _ => _.artLink) || '';
  const artImage = idx(state, _ => _.artImage) || '';
  const artLatitude = idx(state, _ => _.latitude) || '';
  const artLongitude = idx(state, _ => _.longitude) || '';
  const address = idx(state, _ => _.address) || '';
  const city = idx(state, _ => _.city) || '';
  const artState = idx(state, _ => _.state) || '';
  const zipcode = idx(state, _ => _.zip) || '';
  const artistData = idx(state, _ => _.artists) || [];
  const isViewMode = idx(state, _ => _.isViewMode) || false;

  const uploadArtImage = useRef(null);
  const uploadArtistImage = useRef(null);

  const UserSchema = Yup.object().shape({
    art_name: Yup.string()
      .nullable()
      .max(50, 'Too Long!')
      .required('Please enter art name'),
    description: Yup.string()
      .nullable()
      .required('Please enter art description'),
    // link: Yup.string()
    //   .nullable()
    //   .required('Please enter art link'),
    zipcode: Yup.string()
      .max(6, 'Too Long!')
      .required('Please enter zip'),
    state: Yup.string().required('Please enter state'),
    city: Yup.string().required('Please enter city'),
    latitude: Yup.string().required('Please enter latitude'),
    longitude: Yup.string().required('Please enter longitude'),
    address: Yup.string()
      .max(500, 'Too Long!')
      .required('Please enter address'),
    art_type: Yup.string().required('Please select art type'),
    art_image: Yup.string().required('Please select art image'),
    artistArray: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(), // these constraints take precedence
        title: Yup.string(), // these constraints take precedence
        image: Yup.string() // these constraints take precedence
      })
    )
    // these constraints are shown if and only if inner constraints are satisfied
  });

  const AddressSchema = Yup.object().shape({
    zipcode: Yup.string()
      .max(6, 'Too Long!')
      .required('Please enter zipcode'),
    state: Yup.string().required('Please enter state'),
    city: Yup.string().required('Please enter city'),
    address: Yup.string()
      .max(500, 'Too Long!')
      .required('Please enter address')
  });

  const {
    className,
    handleAddUpdate,
    userResponse,
    stateList,
    genderList,
    ...rest
  } = props;

  const classes = useStyles();
  const [artImageData, setArtImageData] = useState('');
  const [artistImageData, setArtistImageData] = useState('');
  const [previousAddress, setPreviousAddress] = useState(address || '');
  const [previousCity, setPreviousCity] = useState(city || '');
  const [previousState, setPreviousState] = useState(artState || '');
  const [previousZipcode, setPreviousZipcode] = useState(zipcode || '');
  const [latLngError, setLatLngError] = useState(null);
  const [values, setValues] = useState({
    art_name: artName || '',
    description: artDesc || '',
    link: artLink || '',
    address: address || '',
    city: city || '',
    state: artState || '',
    zipcode: zipcode || '',
    art_type: artType || '',
    art_image: artImage || '',
    latitude: artLatitude || '',
    longitude: artLongitude || ''
  });
  const [artistArray, setArtistArray] = useState(artistData);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerateLatLng, setIsGenerateLatLng] = useState(false);

  const generatePreviewData = file => {
    const fr = new FileReader();
    return new Promise((resolve, reject) => {
      fr.addEventListener('load', e => {
        let res = fr.result;
        resolve({ uri: res, file: file });
      });
      fr.addEventListener('error', e => {
        reject();
      });
      fr.readAsDataURL(file);
    });
  };

  const onGenerateLatLng = () => {
    if (
      previousAddress === values.address &&
      previousCity === values.city &&
      previousState === values.state &&
      previousZipcode === values.zipcode
    ) {
      return null;
    } else {
      setIsGenerateLatLng(true);
      const obj = {
        address: values.address,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode
      };

      AddressSchema.validate(obj, { abortEarly: false })
        .then(async () => {
          setPreviousAddress(values.address);
          setPreviousCity(values.city);
          setPreviousState(values.state);
          setPreviousZipcode(values.zipcode);

          Geocode.fromAddress(
            `${values.address}, ${values.city}, ${values.state} ${values.zipcode}`
          ).then(
            response => {
              setIsGenerateLatLng(false);
              const { lat, lng } = response.results[0].geometry.location;
              if (lat && lng) {
                setValues({ ...values, latitude: lat, longitude: lng });
                setErrors({ ...errors, latitude: '', longitude: '' });
                setLatLngError(null);
              }
            },
            error => {
              setIsGenerateLatLng(false);
              setLatLngError(
                'To get coordinates from an address please enter valid address, city, state and zipcode'
              );
              setValues({ ...values, latitude: '', longitude: '' });
            }
          );
        })
        .catch(e => {
          setIsGenerateLatLng(false);
          let errorObj = {};
          if (e.inner) {
            e.inner.forEach(el => {
              errorObj = {
                ...errorObj,
                [el.path]: el.message
              };
            });
            setErrors(errorObj);
          } else {
            setErrors(e);
          }
        });
    }
  };

  const handleChange = async event => {
    if (event.target.name === 'address') {
      setValues({ ...values, address: event.target.value });
    }
    if (
      event.target.name === 'art_image' &&
      event.target.files &&
      event.target.files[0]
    ) {
      setErrors({ ...errors, [event.target.name]: '' });
      setArtImageData(event.target.files[0]);
      const img = await generatePreviewData(event.target.files[0]);
      setValues({ ...values, art_image: img.uri });
    } else if (
      event.target.name === 'image' ||
      event.target.name === 'name' ||
      event.target.name === 'title'
    ) {
      let artistDataObject = [];
      if (event.target.name === 'image') {
        setErrors({ ...errors, [`artistArray[0].image`]: '', artistArray: '' });
        setArtistImageData(event.target.files[0]);
        const img = await generatePreviewData(event.target.files[0]);
        artistDataObject = [
          {
            ...artistArray[0],
            image: img.uri
          }
        ];
      } else {
        artistDataObject = [
          {
            ...artistArray[0],
            [event.target.name]: event.target.value
          }
        ];
        setErrors({
          ...errors,
          [`artistArray[0].${event.target.name}`]: '',
          artistArray: ''
        });
      }
      setArtistArray(artistDataObject);
    } else {
      setErrors({ ...errors, [event.target.name]: '' });
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    }
  };

  const updateArtData = data => {
    props.updateArt(
      {
        _id: artId,
        artName: values.art_name,
        description: values.description,
        artLink: values.link,
        address: values.address,
        city: values.city,
        state: values.state,
        zip: values.zipcode,
        latitude: values.latitude,
        longitude: values.longitude,
        artType: values.art_type.toLowerCase(),
        ...data
      },
      res => {
        setIsLoading(false);
        if (res.status === 200) {
          props.enqueueSnackbar('Art updated successfully', {
            variant: 'success'
          });
          navigate('/app/arts', { replace: true });
        } else {
          const errorMessage = _.get(res, 'message', 'An error occured');
          props.enqueueSnackbar(errorMessage, { variant: 'error' });
        }
      }
    );
  };

  const uploadFilesData = async (images, returnImages) => {
    let arrayOfImage = [];
    for (let img of images) {
      const formData = new FormData();
      formData.append('file', img);
      props.uploadImageFiles(formData, res => {
        if (res.status === 200) {
          arrayOfImage.push(res.data);
          if (arrayOfImage.length === images.length) {
            returnImages(arrayOfImage);
          }
        }
      });
    }
  };

  const updateArt = async () => {
    let imageArrayData = [];
    if (artImage !== values.art_image) {
      if (artImageData && artImageData !== '') {
        imageArrayData.push(artImageData);
      }
    }
    if (artistArray.length > 0 && artistData.length > 0) {
      if (artistData[0].image !== artistArray[0].image) {
        if (artistImageData && artistImageData !== '') {
          imageArrayData.push(artistImageData);
        }
      }
    }
    if (imageArrayData.length > 0) {
      await uploadFilesData(imageArrayData, res => {
        if (res.length === imageArrayData.length) {
          if (res.length === 1) {
            if (artImage !== values.art_image) {
              setValues({ ...values, art_image: res[0] });
              let artistData = {};
              if (artistArray.length > 0 && artistArray[0]?.name !== '') {
                artistData = {
                  ...artistData,
                  name: artistArray[0].name
                };
              }
              if (artistArray.length > 0 && artistArray[0]?.title !== '') {
                artistData = {
                  ...artistData,
                  title: artistArray[0].title
                };
              }
              if (artistArray.length > 0 && artistArray[0]?.image !== '') {
                artistData = {
                  ...artistData,
                  image: artistArray[0].image
                };
              }
              updateArtData({
                artImage: res[0],
                artists: [artistData]
              });
            }
            if (artistArray.length > 0 && artistData.length > 0) {
              if (artistData[0].image !== artistArray[0].image) {
                const newArtistData = artistArray.map((artists, index) => {
                  if (index === 0) {
                    return {
                      ...artists,
                      image: res[0]
                    };
                  } else {
                    return artists;
                  }
                });
                setArtistArray(newArtistData);
                updateArtData({
                  artists: [
                    {
                      name: artistArray[0].name,
                      title: artistArray[0].title,
                      image: res[0]
                    }
                  ],
                  artImage: values.art_image
                });
              }
            }
          }
          if (res.length >= 2) {
            const newArtistData = artistArray.map((artists, index) => {
              if (index === 0) {
                return {
                  ...artists,
                  image: res[1]
                };
              } else {
                return artists;
              }
            });
            setArtistArray(newArtistData);
            setValues({ ...values, art_image: res[0] });
            updateArtData({
              artImage: res[0],
              artists: [
                {
                  name: artistArray[0].name,
                  title: artistArray[0].title,
                  image: res[1]
                }
              ]
            });
          }
        }
      });
    } else {
      let data = {};
      if (values.art_image) {
        data = {
          artImage: values.art_image
        };
      }
      if (artistArray.length > 0) {
        let artistData = {};
        if (artistArray.length > 0 && artistArray[0]?.name !== '') {
          artistData = {
            ...artistData,
            name: artistArray[0].name
          };
        }
        if (artistArray.length > 0 && artistArray[0]?.title !== '') {
          artistData = {
            ...artistData,
            title: artistArray[0].title
          };
        }
        if (artistArray.length > 0 && artistArray[0]?.image !== '') {
          artistData = {
            ...artistData,
            image: artistArray[0].image
          };
        }
        data = {
          ...data,
          artists: [artistData]
        };
      }
      updateArtData(data);
    }
  };


  const handleAddArt = async () => {
    const { addArt } = props;

    let imageArrayData = [artImageData];
    if (artistImageData !== '') {
      imageArrayData = [...imageArrayData, artistImageData];
    }
    await uploadFilesData(imageArrayData, res => {
      try {
        let reqData = {
          artName: values.art_name,
          description: values.description,
          artLink: values.link,
          address: values.address,
          city: values.city,
          state: values.state,
          zip: values.zipcode,
          latitude: values.latitude,
          longitude: values.longitude,
          artType: values.art_type.toLowerCase(),
          artImage: res[0]
        };
        if (artistArray.length > 0) {
          let artistData = {};
          if (artistArray.length > 0 && artistArray[0]?.name !== '') {
            artistData = {
              ...artistData,
              name: artistArray[0].name
            };
          }
          if (artistArray.length > 0 && artistArray[0]?.title !== '') {
            artistData = {
              ...artistData,
              title: artistArray[0].title
            };
          }
          if (artistArray.length > 0 && artistArray[0]?.image !== '') {
            artistData = {
              ...artistData,
              image: artistArray[0].image
            };
          }
          reqData = {
            ...reqData,
            artists: [artistData]
          };
        }
        addArt(reqData, res => {
          setIsLoading(false);
          if (res.status === 200) {
            const successMessage = _.get(res, 'data', 'Art added successfully');
            props.enqueueSnackbar(successMessage, {
              variant: 'success'
            });
            navigate('/app/arts', { replace: true });
          } else {
            const errorMessage = _.get(res, 'message', 'An error occured');
            props.enqueueSnackbar(errorMessage, { variant: 'error' });
          }
        });
      } catch (error) {
        setIsLoading(false);
      }
    });
  };

  const handleAddClick = () => {
    const { addArt, enqueueSnackbar } = props;
    const obj = { ...values, artistArray };

    setIsLoading(true);
    UserSchema.validate(obj, { abortEarly: false })
      .then(async () => {
        if (artId) {
          updateArt();
        } else {
          handleAddArt();
        }
      })
      .catch(e => {
        setIsLoading(false);
        let errorObj = {};
        if (e.inner) {
          e.inner.forEach(el => {
            errorObj = {
              ...errorObj,
              [el.path]: el.message
            };
          });
          setErrors(errorObj);
        } else {
          setErrors(e);
        }
      });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardContent>
          <Grid style={{ marginBottom: '10px' }} justify="flex-end" container>
            <CommonSelector
              gridProps={{ xs: 5, md: 2, sm: 3 }}
              margin="dense"
              disabled={isViewMode}
              title={values.art_type !== '' ? values.art_type : 'Art Type'}
              options={artTypeList}
              value={'art_type'}
              error={errors['art_type']}
              fieldVal={values['art_type']}
              onChange={e => handleChange(e)}
            />
          </Grid>
          <Grid container spacing={3}>
            <CommonTextField
              disabled={isViewMode}
              name={'Art name'}
              value={'art_name'}
              onChange={e => handleChange(e)}
              md={12}
              error={errors['art_name']}
              fieldVal={values['art_name']}
            />
            <CommonTextField
              disabled={isViewMode}
              name={'Art Description'}
              value={'description'}
              onChange={e => handleChange(e)}
              md={12}
              error={errors['description']}
              fieldVal={values['description']}
            />
            <CommonTextField
              disabled={isViewMode}
              name={'Art Link'}
              value={'link'}
              onChange={e => handleChange(e)}
              md={12}
              inputRequired={false}
              // inputRequired={false}
              // error={errors['link']}
              fieldVal={values['link']}
            />

            <CommonTextField
              disabled={isViewMode}
              name={'Address'}
              value={'address'}
              onChange={e => handleChange(e)}
              md={12}
              error={errors['address']}
              fieldVal={values['address']}
            />
            <CommonTextField
              disabled={isViewMode}
              name={'City'}
              value={'city'}
              md={4}
              onChange={e => handleChange(e)}
              error={errors['city']}
              fieldVal={values['city']}
            />
            <CommonTextField
              disabled={isViewMode}
              name={'State'}
              value={'state'}
              md={4}
              onChange={e => handleChange(e)}
              error={errors['state']}
              fieldVal={values['state']}
            />
            <CommonTextField
              disabled={isViewMode}
              name={'Zip code'}
              value={'zipcode'}
              md={4}
              onChange={e => handleChange(e)}
              error={errors['zipcode']}
              fieldVal={values['zipcode']}
            />
            <CommonTextField
              disabled={isViewMode}
              name={'Latitude'}
              value={'latitude'}
              md={4}
              onChange={e => handleChange(e)}
              error={errors['latitude']}
              fieldVal={values['latitude']}
            />
            <CommonTextField
              disabled={isViewMode}
              name={'Longitude'}
              value={'longitude'}
              md={4}
              onChange={e => handleChange(e)}
              error={errors['longitude']}
              fieldVal={values['longitude']}
            />
            {isViewMode ? null : (
              <CommonButton
                style={{ height: 50, margin: 12 }}
                title={'Get coordinates'}
                isLoading={isGenerateLatLng}
                onClick={onGenerateLatLng}
              />
            )}
          </Grid>
          {latLngError ? (
            <Typography
              className={classes.errorTextStyle}
              style={{ fontSize: '0.9rem' }}
            >
              {latLngError}
            </Typography>
          ) : null}
          <Grid
            xs={12}
            item
            container
            className={classes.artistInputParentView}
          >
            <Typography>Artist Details</Typography>
            <Grid
              xs={12}
              item
              container
              display={'flex'}
              direction={'row'}
              alignItems={'center'}
              style={
                artistArray.length > 1
                  ? {
                      border: '0.5px solid #c4c4c4',
                      borderRadius: 4,
                      padding: 20
                    }
                  : {}
              }
            >
              {/* {/* <Grid item xs={3} md={2} className={classes.imageView}>
                <div className={classes.imageChildView}>
                  <img
                    src={
                      (artistArray.length > 0 && artistArray[0].image) ||
                      'https://getstamped.co.uk/wp-content/uploads/WebsiteAssets/Placeholder.jpg'
                    }
                    name={'image'}
                    style={{
                      height: 80,
                      width: 80,
                      border: '1px solid #c4c4c4',
                      borderRadius: 4
                    }}
                    disabled={isViewMode}
                    onClick={
                      !isViewMode
                        ? () => {
                            if (uploadArtistImage) {
                              uploadArtistImage.current.click();
                            }
                          }
                        : null
                    }
                  />
                  {!isViewMode &&
                  artistArray.length > 0 &&
                  artistArray[0].image ? (
                    <IconButton
                      className={classes.closeButtonStyle}
                      onClick={() => {
                        const newArtistData = artistArray.map(
                          (artists, index) => {
                            if (index === 0) {
                              return {
                                ...artists,
                                image: ''
                              };
                            } else {
                              return artists;
                            }
                          }
                        );
                        setArtistArray(newArtistData);
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  ) : null}
                </div>
                {errors['artistArray[0].image'] || errors['artistArray'] ? (
                  <Typography className={classes.errorTextStyle}>
                    {`${
                      errors['artistArray[0].image']
                        ? errors['artistArray[0].image']
                        : ''
                    }
                      ${errors['artistArray'] ? errors['artistArray'] : ''}`}
                  </Typography>
                ) : null}
                <input
                  ref={uploadArtistImage}
                  className={classes.uploadButton}
                  accept="image/*"
                  multiple
                  type={'file'}
                  id={'artistImage'}
                  name={'image'}
                  value={''}
                  onChange={handleChange}
                  style={{
                    marginLeft: '20px'
                  }}
                />
              </Grid> */}
              <Grid
                style={{ marginBottom: '10px' }}
                justify="flex-end"
                container
              >
                <CommonTextField
                  disabled={isViewMode}
                  name={'Artist Name'}
                  value={'name'}
                  inputRequired={false}
                  md={12}
                  xs={12}
                  onChange={e => handleChange(e)}
                  error={
                    errors['artistArray[0].name'] || errors['artistArray']
                      ? `${
                          errors['artistArray[0].name']
                            ? errors['artistArray[0].name']
                            : ''
                        } ${errors['artistArray'] ? errors['artistArray'] : ''}`
                      : ''
                  }
                  fieldVal={
                    (artistArray.length > 0 && artistArray[0].name) || ''
                  }
                />
                {/* <CommonTextField
                  className={classes.artistTitleText}
                  disabled={isViewMode}
                  name={'Artist Title'}
                  value={'title'}
                  inputRequired={false}
                  md={12}
                  xs={12}
                  onChange={e => handleChange(e)}
                  error={
                    errors['artistArray[0].title'] || errors['artistArray']
                      ? `${
                          errors['artistArray[0].title']
                            ? errors['artistArray[0].title']
                            : ''
                        } ${errors['artistArray'] ? errors['artistArray'] : ''}`
                      : ''
                  }
                  fieldVal={
                    (artistArray.length > 0 && artistArray[0].title) || ''
                  }
                /> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            xs={isViewMode ? 4 : 12}
            md={isViewMode ? 2 : 6}
            lg={isViewMode ? 2 : 4}
            className={classes.artImageParentView}
          >
            <Typography style={{ marginBottom: 10 }}>Art Image</Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around'
              }}
            >
              <div className={classes.imageChildView}>
                <img
                  src={
                    values.art_image ||
                    'https://getstamped.co.uk/wp-content/uploads/WebsiteAssets/Placeholder.jpg'
                  }
                  style={{
                    height: 80,
                    width: 80,
                    border: '1px solid #c4c4c4',
                    borderRadius: 4
                  }}
                />
                {errors['art_image'] ? (
                  <Typography className={classes.errorTextStyle}>
                    {errors['art_image']}
                  </Typography>
                ) : null}
                {!isViewMode && values.art_image ? (
                  <IconButton
                    className={classes.closeButtonStyle}
                    onClick={() => {
                      setValues({ ...values, art_image: '' });
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                ) : null}
              </div>
              {isViewMode ? null : (
                <>
                  <CommonButton
                    onClick={() => {
                      if (uploadArtImage) {
                        uploadArtImage.current.click();
                      }
                    }}
                    title={'Upload Art Image'}
                    style={{ marginTop: 20 }}
                  />
                  <input
                    ref={uploadArtImage}
                    className={classes.uploadButton}
                    accept="image/*"
                    multiple
                    type={'file'}
                    id={'artImage'}
                    name={'art_image'}
                    value={''}
                    onChange={handleChange}
                  />
                </>
              )}
            </div>
          </Grid>
        </CardContent>
        <Divider />
        {isViewMode ? null : (
          <Box display="flex" justifyContent="flex-end" p={2}>
            <CommonButton
              themeColor={''}
              style={{ width: '130px' }}
              className={classes.cancelbtnStyle}
              title={'Cancel'}
              // isLoading={isLoading}
              onClick={() => {
                navigate('/app/arts', { replace: true });
              }}
            />
            <CommonButton
              style={{ width: '130px', marginLeft: '20px' }}
              title={artId ? 'Update' : 'Add'}
              isLoading={isLoading}
              onClick={() => {
                handleAddClick();
              }}
            />
          </Box>
        )}
      </Card>
    </form>
  );
};

AddArt.propTypes = {
  className: PropTypes.string,
  userdetails: PropTypes.object
};

const mapStateToProps = state => {
  const { artsReducer } = state;
  const {
    addArtIsInProgress,
    addArtError,
    uploadArtImageIsInProgress,
    uploadArtImageError,
    updateArtIsInProgress,
    updateArtError
  } = artsReducer;
  return {
    addArtIsInProgress,
    addArtError,
    uploadArtImageIsInProgress,
    uploadArtImageError,
    updateArtIsInProgress,
    updateArtError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getArtsList: callBack => dispatch({ type: REQUEST_ARTS_LIST, callBack }),
    addArt: (data, callBack) =>
      dispatch({ type: REQUEST_ADD_ARTS, data, callBack }),
    updateArt: (data, callBack) =>
      dispatch({ type: REQUEST_UPDATE_ART, data, callBack }),
    uploadImageFiles: (data, callBack) =>
      dispatch({ type: REQUEST_UPLOAD_ART_IMAGE, data, callBack })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AddArt));
