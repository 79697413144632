import axios from 'axios';
import idx from 'idx';
import {
  ADD_USER,
  GET_USERS,
  DEFAULT_ERROR_RESPONSE,
  GET_ARTS,
  LOGIN,
  ADD_ART,
  DELETE_USER,
  UPDATE_USER,
  UPDATE_ART,
  DELETE_ART,
  UPLOAD_ART_IMAGE,
  CHANGE_PASSWORD,
  BULK_IMPORT_ART,
  BULK_EXPORT_ART,
  BULK_IMPORT_USER,
  BULK_EXPORT_USER
} from 'src/constants/api';
import { AUTH_TOKEN } from 'src/constants/appConst';

export async function addUser(data) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .post(ADD_USER, data, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}
export async function updateUser(data) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .put(`${UPDATE_USER}/${data._id}`, data, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function deleteUser(data) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .delete(`${DELETE_USER}/${data._id}`, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function addArts(data) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .post(ADD_ART, data, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function uploadArtImage(data) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .post(`${UPLOAD_ART_IMAGE}`, data, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function updateArt(data) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .put(`${UPDATE_ART}/${data._id}`, data, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function deleteArt(data) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .delete(`${DELETE_ART}/${data._id}`, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function getArtsList(pageSize, pageNumber) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .get(`${GET_ARTS}?page_size=${pageSize}&page_number=${pageNumber}`, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function getUserList(pageSize, pageNumber) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .get(`${GET_USERS}?page_size=${pageSize}&page_number=${pageNumber}`, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function login(data) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .post(LOGIN, data, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function changePassword(data) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .put(`${CHANGE_PASSWORD}`, data, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function importArts(data) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .post(`${BULK_IMPORT_ART}`, data, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function exportArts() {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') },
    responseType: 'blob'
  };
  return axios
    .get(`${BULK_EXPORT_ART}`, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      const url = window.URL.createObjectURL(new Blob([authData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'artData.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function importUser(data) {
  let config = {
    headers: { Authorization: localStorage.getItem('isAuthenticate') }
  };
  return axios
    .post(`${BULK_IMPORT_USER}`, data, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}

export async function exportUser() {
  let config = {
    headers: {
      Authorization: localStorage.getItem('isAuthenticate')
    },
    responseType: 'blob'
  };
  return axios

    .get(`${BULK_EXPORT_USER}`, config)
    .then(res => {
      const authData = idx(res, _ => _.data);
      const status = idx(res, _ => _.status);
      const url = window.URL.createObjectURL(new Blob([authData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'userData.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      return { status, data: authData };
    })
    .catch(error => {
      const status = idx(error, _ => _.response.status);
      const eData = idx(error, _ => _.response.data) || DEFAULT_ERROR_RESPONSE;
      return { status, data: eData };
    });
}
