import { call, put } from 'redux-saga/effects';
import {
  REQUEST_LOGIN_ERROR,
  REQUEST_LOGIN_SUCCESS,
  REQUEST_CHANGE_PASSWORD_SUCCESS,
  REQUEST_CHANGE_PASSWORD_ERROR
} from '../actions/loginActions';
import { changePassword, login } from '../Api';

export function* requestLoginSaga(action) {
  try {
    const res = yield call(login, action.data);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_LOGIN_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_LOGIN_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_LOGIN_ERROR, error });
    action.callBack(error);
  }
}

export function* requestChangePasswordSaga(action) {
  try {
    const res = yield call(changePassword, action.data);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_CHANGE_PASSWORD_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_CHANGE_PASSWORD_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_CHANGE_PASSWORD_ERROR, error });
    action.callBack(error);
  }
}
