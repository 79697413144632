import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  requestAddUserSaga,
  requestDeleteUserSaga,
  requestExportUserSaga,
  requestGetUserSaga,
  requestImportUserSaga,
  requestUpdateUserSaga
} from './userSaga';
import {
  REQUEST_ADD_USER,
  REQUEST_DELETE_USER,
  REQUEST_EXPORT_USER,
  REQUEST_GET_USERS_LIST,
  REQUEST_IMPORT_USER,
  REQUEST_UPDATE_USER
} from '../actions/userActions';
import {} from './commonSaga';
import {} from '../actions/commonActions';
import {
  REQUEST_CHANGE_PASSWORD,
  REQUEST_LOGIN
} from '../actions/loginActions';
import { requestChangePasswordSaga, requestLoginSaga } from './loginSaga';
import {
  requestArtsListSaga,
  requestAddArtsSaga,
  requestUpdateArtSaga,
  requestDeleteArtSaga,
  requestUploadArtImageSaga,
  requestImportArtSaga,
  requestExportArtSaga
} from './artsSaga';
import {
  REQUEST_ADD_ARTS,
  REQUEST_ARTS_LIST,
  REQUEST_DELETE_ART,
  REQUEST_IMPORT_ART,
  REQUEST_EXPORT_ART,
  REQUEST_UPDATE_ART,
  REQUEST_UPLOAD_ART_IMAGE
} from '../actions/artsActions';

export function* watcherSaga() {
  yield takeLatest(REQUEST_ADD_USER, requestAddUserSaga);
  yield takeLatest(REQUEST_GET_USERS_LIST, requestGetUserSaga);
  yield takeLatest(REQUEST_LOGIN, requestLoginSaga);
  yield takeLatest(REQUEST_CHANGE_PASSWORD, requestChangePasswordSaga);
  yield takeLatest(REQUEST_ARTS_LIST, requestArtsListSaga);
  yield takeLatest(REQUEST_ADD_ARTS, requestAddArtsSaga);
  yield takeEvery(REQUEST_UPLOAD_ART_IMAGE, requestUploadArtImageSaga);
  yield takeLatest(REQUEST_UPDATE_ART, requestUpdateArtSaga);
  yield takeLatest(REQUEST_DELETE_ART, requestDeleteArtSaga);
  yield takeLatest(REQUEST_UPDATE_USER, requestUpdateUserSaga);
  yield takeLatest(REQUEST_DELETE_USER, requestDeleteUserSaga);
  yield takeLatest(REQUEST_IMPORT_ART, requestImportArtSaga);
  yield takeLatest(REQUEST_EXPORT_ART, requestExportArtSaga);
  yield takeLatest(REQUEST_IMPORT_USER, requestImportUserSaga);
  yield takeLatest(REQUEST_EXPORT_USER, requestExportUserSaga);
}
