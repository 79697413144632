import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { CommonButton } from 'src/components/CommonButton';
import { CommonInput } from 'src/components/CommonInput';
import { REQUEST_LOGIN } from 'src/redux/actions/loginActions';
import { useNavigate } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { appName } from 'src/constants/appConst';
import idx from 'idx';
import * as Yup from 'yup';
import LOGO_ICON from '../../assets/images/logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'inherit'
  },
  textInputView: {
    width: '100%',
    marginBottom: '1rem'
  },
  textInputParentView: {
    marginBottom: '2rem',
    // marginTop: '1rem',
    flex: 0.2
  },
  inputWrapper: {
    padding: 6
  },
  loginTitle: {
    color: theme.palette.primary.main
  }
}));

const Login = props => {
  const LoginSchema = Yup.object().shape({
    userName: Yup.string().email('Please enter valid email'),
    password: Yup.string().required('Please enter password')
  });

  const classes = useStyles();
  const navigate = useNavigate();
  const { login, loginIsInProgress, enqueueSnackbar } = props;

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (text, setText, error) => {
    setText(text);
    setErrors({ ...errors, [error]: '' });
  };

  return (
    <div className={classes.root}>
      <img src={LOGO_ICON} style={{ height: 100, width: 100 }} />
      <Typography
        color={'textPrimary'}
        gutterBottom
        variant="h6"
        style={{ marginTop: '1rem' }}
      >
        LOGIN
      </Typography>
      <Grid
        className={classes.textInputParentView}
        item
        container
        xs={10}
        md={6}
        lg={3}
        display={'flex'}
        direction={'column'}
        alignItems={'center'}
      >
        <CommonInput
          value={userName}
          onChange={event =>
            handleChange(event.target.value, setUserName, 'userName')
          }
          inputWrapper={classes.inputWrapper}
          placeholder={'Email'}
          error={errors['userName']}
        />
        <CommonInput
          value={password}
          type="password"
          inputWrapper={classes.inputWrapper}
          onChange={event =>
            handleChange(event.target.value, setPassword, 'password')
          }
          placeholder={'Password'}
          error={errors['password']}
        />
      </Grid>
      <CommonButton
        style={{ width: '130px' }}
        disabled={userName === '' || password === '' || loginIsInProgress}
        title={'Sign In'}
        isLoading={loginIsInProgress}
        onClick={() => {
          const obj = { userName, password };
          LoginSchema.validate(obj, { abortEarly: false })
            .then(async () => {
              login({ email: userName, password: password }, res => {
                if (res.status === 200) {
                  const token = idx(res, _ => _.data.token) || '';
                  const role = idx(res, _ => _.data.user.role) || '';
                  const loginUser = idx(res, _ => _.data.user) || undefined;
                  localStorage.setItem('isAuthenticate', `Bearer ${token}`);
                  localStorage.setItem('userRole', role);
                  localStorage.setItem('loginUser', JSON.stringify(loginUser));
                  navigate('/');
                  enqueueSnackbar('Login successful', { variant: 'success' });
                } else {
                  const errorMessage =
                    idx(res, _ => _.message) || 'Login failed';
                  enqueueSnackbar(errorMessage, { variant: 'error' });
                }
              });
            })
            .catch(e => {
              let errorObj = {};
              if (e.inner) {
                e.inner.forEach(el => {
                  errorObj = {
                    ...errorObj,
                    [el.path]: el.message
                  };
                });
                setErrors(errorObj);
              } else {
                setErrors(e);
              }
            });
        }}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const { loginReducer } = state;
  const { loginIsInProgress, loginResponse, loginError } = loginReducer;
  return {
    loginIsInProgress,
    loginResponse,
    loginError
  };
};

const mapDispatchToProps = dispatch => ({
  login: (data, callBack) => dispatch({ type: REQUEST_LOGIN, data, callBack })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Login));
