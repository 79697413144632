import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  IconButton
} from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from 'react-redux';
import { strings } from 'src/utils/strings';
import AddUser from './AddUser';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import idx from 'idx';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2.4)
  },
  memberContainer: {},
  container: {
    marginBottom: '20px'
  },
  slotStyle: {
    marginRight: '5px',
    fontSize: '16px'
  },
  margin5x: {
    marginLeft: '10px',
    marginTop: '5px'
  },
  userInfo: {
    marginTop: '5px',
    flexDirection: 'row',
    display: 'flex'
  }
}));

const Users = props => {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isViewMode = idx(state, _ => _.isViewMode) || false;
  const isEdit = idx(state, _ => _._id) || false;

  return (
    <Page
      className={classes.root}
      title={isEdit ? strings.update_users : strings.add_user}
    >
      <Container maxWidth={false}>
        <Grid
          alignItems="center"
          className={classes.container}
          justify="space-between"
          container
        >
          <Grid alignItems={'center'} container>
            <IconButton
              onClick={() => {
                navigate('/app/users', { replace: true });
              }}
            >
              <ArrowBackIcon fontSize={'large'} color={'primary'} />
            </IconButton>
            {!isViewMode && (
              <Typography color="textPrimary" variant="h6">
                {isEdit
                  ? strings.update_users.toUpperCase()
                  : strings.add_user.toUpperCase()}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Grid
              justify={'flex-end'}
              direction={'row'}
              container
              sm={12}
              alignItems="center"
            >
              <div className={classes.userInfo}>{/* {'ART TYPE LIST'} */}</div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AddUser {...props} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  const {} = state;
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
