import {
  REQUEST_CHANGE_PASSWORD,
  REQUEST_CHANGE_PASSWORD_ERROR,
  REQUEST_CHANGE_PASSWORD_SUCCESS,
  REQUEST_LOGIN,
  REQUEST_LOGIN_ERROR,
  REQUEST_LOGIN_SUCCESS
} from '../actions/loginActions';

const initialState = {
  loginIsInProgress: false,
  loginResponse: null,
  loginError: null,
  changePasswordIsInProgress: false,
  changePasswordResponse: null,
  changePasswordError: null,
  isAuthenticate: false
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case REQUEST_LOGIN:
      return {
        ...state,
        loginIsInProgress: true
      };
    case REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        loginIsInProgress: false,
        loginResponse: action.data,
        isAuthenticate: true
      };
    case REQUEST_LOGIN_ERROR:
      return {
        ...state,
        loginIsInProgress: false,
        loginResponse: null,
        loginError: action.data
      };

    // Change Password
    case REQUEST_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordIsInProgress: true
      };
    case REQUEST_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordIsInProgress: false,
        changePasswordResponse: action.data
      };
    case REQUEST_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordIsInProgress: false,
        changePasswordResponse: null,
        changePasswordError: action.data
      };
    default:
      return state;
  }
};

export default loginReducer;
