import React from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, ListItem, makeStyles } from '@material-ui/core';
import idx from 'idx';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: '8px'
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 20px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto',
    color: 'black',
    fontFamily: 'Roboto'
  },
  active: {
    border: '2px solid',
    borderColor: theme.palette.primary,
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const getHrefTitle = (href, id) => {
  switch (href) {
    case '/app/arts':
    case `/app/art/${id}`:
    case `/app/updateArt/${id}`:
    case '/app/addArt':
    case '/':
      return 'Arts';
    case '/app/users':
    case `/app/user/${id}`:
    case `/app/updateUser/${id}`:
    case '/app/addUser':
      return 'Users';
    default:
      break;
  }
};

const NavItem = ({
  className,
  href,
  onClick,
  icon: Icon,
  title,
  activePath,
  ...rest
}) => {
  const classes = useStyles();
  const { state } = useLocation();
  const id = idx(state, _ => _._id) || '';
  const hrefTitle = getHrefTitle(activePath, id);
  const isActiveDrawerItem = hrefTitle === title;

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        activeClassName={classes.active}
        className={clsx(classes.button, isActiveDrawerItem && classes.active)}
        component={RouterLink}
        to={href}
      >
        {Icon && <Icon className={classes.icon} color={'black'} size={'20'} />}
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
