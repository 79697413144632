import { call, put } from 'redux-saga/effects';
import {
  REQUEST_ADD_USER_ERROR,
  REQUEST_ADD_USER_SUCCESS,
  REQUEST_GET_USERS_LIST_SUCCESS,
  REQUEST_GET_USERS_LIST_ERROR,
  REQUEST_UPDATE_USER_SUCCESS,
  REQUEST_UPDATE_USER_ERROR,
  REQUEST_DELETE_USER_SUCCESS,
  REQUEST_DELETE_USER_ERROR,
  REQUEST_IMPORT_USER_SUCCESS,
  REQUEST_IMPORT_USER_ERROR,
  REQUEST_EXPORT_USER_SUCCESS,
  REQUEST_EXPORT_USER_ERROR
} from '../actions/userActions';
import {
  addUser,
  deleteUser,
  exportUser,
  getUserList,
  importUser,
  updateUser
} from '../Api';

export function* requestAddUserSaga(action) {
  try {
    const res = yield call(addUser, action.data);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_ADD_USER_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_ADD_USER_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_ADD_USER_ERROR, error });
    action.callBack(error);
  }
}

export function* requestUpdateUserSaga(action) {
  try {
    const res = yield call(updateUser, action.data);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_UPDATE_USER_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_UPDATE_USER_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_UPDATE_USER_ERROR, error });
    action.callBack(error);
  }
}

export function* requestDeleteUserSaga(action) {
  try {
    const res = yield call(deleteUser, action.data);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_DELETE_USER_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_DELETE_USER_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_DELETE_USER_ERROR, error });
    action.callBack(error);
  }
}

export function* requestGetUserSaga(action) {
  try {
    const res = yield call(getUserList, action.pageSize, action.pageNumber);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_GET_USERS_LIST_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_GET_USERS_LIST_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_GET_USERS_LIST_ERROR, error });
    action.callBack(error);
  }
}

export function* requestImportUserSaga(action) {
  try {
    const res = yield call(importUser, action.data);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_IMPORT_USER_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_IMPORT_USER_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_IMPORT_USER_ERROR, error });
    action.callBack(error);
  }
}

export function* requestExportUserSaga(action) {
  try {
    const res = yield call(exportUser);
    const status = res.status;
    const data = res.data;

    if (status === 200) {
      yield put({ type: REQUEST_EXPORT_USER_SUCCESS, data });
      action.callBack(data);
    } else {
      yield put({ type: REQUEST_EXPORT_USER_ERROR, data });
      action.callBack(data);
    }
  } catch (error) {
    yield put({ type: REQUEST_EXPORT_USER_ERROR, error });
    action.callBack(error);
  }
}
