export const strings = {
  wentWrong: 'Something went wrong, please try again!',
  noArtText: 'No arts to display',
  noUsersText: 'No users to display',
  arts: 'Arts',
  users: 'Users',
  add_arts: 'Add Arts',
  add_users: 'Add User',
  update_users: 'Update User',
  add_art: 'Add Art',
  import_art: 'Import Art',
  export_art: 'Export Art',
  import_user: 'Import User',
  export_user: 'Export User',
  update_art: 'Update Art',
  add_user: 'Add User',
  change_password: 'Change Password',
  dropFiles: 'Drop your file here',
  import: 'Import',
};
