import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
  withStyles,
  Button
} from '@material-ui/core';
import CoreMenu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import { useNavigate } from 'react-router-dom';
import { appName } from 'src/constants/appConst';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { ConfirmationModal } from 'src/components/ConfirmationModal';
import LOGO_ICON from '../../assets/images/logo.png';

const useStyles = makeStyles(theme => ({
  root: { backgroundColor: 'White' },
  avatar: {
    width: 60,
    height: 60
  },
  infoParentView: { display: 'flex', alignItems: 'center' },
  roundAvatar: { height: 40, width: 40, borderRadius: 20 },
  nameStyle: {
    color: 'black',
    fontWeight: 'bold',
    marginLeft: 15,
    marginRight: 25,
    fontFamily: 'Roboto'
  },
  emailStyle: {
    lineHeight: 0,
    color: 'rgba(114, 114, 114, 1)',
    fontSize: '15px',
    fontFamily: 'Roboto'
  },
  menuParentView: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20
  },
  menuChildView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    textTransform: 'none',
    padding: 0,
    justifyContent: 'flex-start'
  },
  menuButtonStyle: { marginRight: 20, padding: 0 },
  deleteModalIconStyle: {
    color: theme.palette.primary.main,
    marginTop: 15,
    marginBottom: 15
  },
  padding: {
    paddingLeft: 15,
    paddingRight: 15
  },
  paddingVertical: {
    paddingTop: 15,
    paddingBottom: 15
  },
  titleParentView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15
  },
  titleChildView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  modalButtonParentView: {
    paddingBottom: 15
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontFamily: 'Roboto'
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid rgba(114, 114, 114, 0.2)',
    padding: 0,
    marginTop: 9
  }
})(props => (
  <CoreMenu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom'
    }}
    transformOrigin={{
      vertical: 'top'
    }}
    {...props}
  />
));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [loggedinUser, setLoggedinUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false);

  const storeLoginUser = async () => {
    const loginUser = JSON.parse(localStorage.getItem('loginUser'));
    if (loginUser) {
      setLoggedinUser(loginUser);
    }
  };

  useEffect(() => {
    storeLoginUser();
  }, []);

  const getUserName = () => {
    if (loggedinUser && loggedinUser.name)
      return `${loggedinUser.name
        .charAt(0)
        .toUpperCase()}${loggedinUser.name.substring(1)}`;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar className={clsx(classes.root, className)} elevation={2} {...rest}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon color={'primary'} />
          </IconButton>
        </Hidden>
        <Box>
          <img src={LOGO_ICON} style={{ height: 40, width: 40 }} />
        </Box>
        <Box flexGrow={1} />

        <div
          className={classes.infoParentView}
          onClick={event => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <img
            src={'https://www.ajmc.com/avatar_placeholder.png'}
            alt=""
            className={classes.roundAvatar}
          />
          <label for="username" className={classes.nameStyle}>
            {loggedinUser && loggedinUser.name ? getUserName() : ''}
          </label>
          {loggedinUser && loggedinUser.email ? (
            <label for="email" className={classes.emailStyle}>
              {loggedinUser.email || ''}
            </label>
          ) : null}
          <IconButton
            color="inherit"
            onClick={event => {
              setAnchorEl(event.currentTarget);
            }}
          >
            {anchorEl ? (
              <ExpandLessIcon fontSize={'medium'} color={'primary'} />
            ) : (
              <ExpandMoreIcon fontSize={'medium'} color={'primary'} />
            )}
          </IconButton>
        </div>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleClose()}
        >
          <div className={classes.menuParentView}>
            <Button
              className={classes.menuChildView}
              onClick={() => {
                setAnchorEl(null);
                navigate('/app/changePassword', { replace: true });
              }}
            >
              <IconButton
                color="inherit"
                // onClick={() => {
                //   navigate('/login', { replace: true });
                //   localStorage.removeItem('isAuthenticate');
                //   localStorage.removeItem('userRole');
                // }}
                className={classes.menuButtonStyle}
              >
                <VpnKeyIcon fontSize={'medium'} color={'primary'} />
              </IconButton>
              <Typography>Change Password</Typography>
            </Button>
            <Button
              className={classes.menuChildView}
              onClick={() => {
                setIsOpenLogoutModal(true);
                setAnchorEl(null);
              }}
            >
              <IconButton color="inherit" className={classes.menuButtonStyle}>
                <ExitToAppIcon fontSize={'medium'} color={'primary'} />
              </IconButton>
              <Typography>Logout</Typography>
            </Button>
          </div>
        </StyledMenu>
      </Toolbar>
      {isOpenLogoutModal ? (
        <ConfirmationModal
          open={isOpenLogoutModal}
          title={'Logout'}
          subTitle={'Are you sure you want to logout?'}
          classes={classes}
          onClose={() => setIsOpenLogoutModal(false)}
          onConfirmation={() => {
            navigate('/login', { replace: true });
            localStorage.removeItem('isAuthenticate');
            localStorage.removeItem('userRole');
          }}
          // isConfirmationInProgress={deleteInProgress}
          icon={() => (
            <ExitToAppIcon className={classes.deleteModalIconStyle} />
          )}
          wrapperClass={classes.paddingVertical}
          buttonWrapper={classes.modalButtonParentView}
          titleWrapper={classes.modalTitle}
          fullWidth
        />
      ) : null}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
