import {
  REQUEST_ADD_ARTS,
  REQUEST_ADD_ARTS_ERROR,
  REQUEST_ADD_ARTS_SUCCESS,
  REQUEST_ARTS_LIST,
  REQUEST_ARTS_LIST_ERROR,
  REQUEST_ARTS_LIST_SUCCESS,
  REQUEST_DELETE_ART,
  REQUEST_DELETE_ART_ERROR,
  REQUEST_DELETE_ART_SUCCESS,
  REQUEST_IMPORT_ART,
  REQUEST_IMPORT_ART_ERROR,
  REQUEST_IMPORT_ART_SUCCESS,
  REQUEST_EXPORT_ART,
  REQUEST_UPDATE_ART,
  REQUEST_UPDATE_ART_ERROR,
  REQUEST_UPDATE_ART_SUCCESS,
  REQUEST_UPLOAD_ART_IMAGE,
  REQUEST_UPLOAD_ART_IMAGE_ERROR,
  REQUEST_UPLOAD_ART_IMAGE_SUCCESS,
  REQUEST_EXPORT_ART_SUCCESS,
  REQUEST_EXPORT_ART_ERROR
} from '../actions/artsActions';

const initialState = {
  artsList: [],
  artsListIsInProgress: false,
  artsListError: null,
  addArtIsInProgress: false,
  addArtError: null,
  addArtResponse: null,
  uploadArtImageResponse: null,
  uploadArtImageIsInProgress: false,
  uploadArtImageError: null,
  updateArtIsInProgress: false,
  updateArtError: null,
  updateArtResponse: null,
  deleteArtIsInProgress: false,
  deleteArtError: null,
  deleteArtResponse: null,
  importArtList: [],
  importArtIsInProgress: false,
  importArtError: null,
  exportArtList: [],
  exportArtIsInProgress: false,
  exportArtError: null
};

const artsReducer = (state = initialState, action) => {
  switch (action.type) {
    // get art list
    case REQUEST_ARTS_LIST:
      return {
        ...state,
        artsList: [],
        artsListIsInProgress: true,
        artsListError: null
      };
    case REQUEST_ARTS_LIST_SUCCESS:
      return {
        ...state,
        artsListIsInProgress: false,
        artsListError: null,
        artsList: action.data
      };
    case REQUEST_ARTS_LIST_ERROR:
      return {
        ...state,
        artsListIsInProgress: false,
        artsListError: action.data,
        artsList: []
      };

    // add art
    case REQUEST_ADD_ARTS:
      return {
        ...state,
        addArtResponse: null,
        addArtIsInProgress: true,
        addArtError: null
      };
    case REQUEST_ADD_ARTS_SUCCESS:
      return {
        ...state,
        addArtIsInProgress: false,
        addArtError: null,
        addArtResponse: action.data
      };
    case REQUEST_ADD_ARTS_ERROR:
      return {
        ...state,
        addArtIsInProgress: false,
        addArtError: action.data,
        addArtResponse: null
      };

    // upload art image
    case REQUEST_UPLOAD_ART_IMAGE:
      return {
        ...state,
        uploadArtImageResponse: null,
        uploadArtImageIsInProgress: true,
        uploadArtImageError: null
      };
    case REQUEST_UPLOAD_ART_IMAGE_SUCCESS:
      return {
        ...state,
        uploadArtImageIsInProgress: false,
        uploadArtImageError: null,
        uploadArtImageResponse: action.data
      };
    case REQUEST_UPLOAD_ART_IMAGE_ERROR:
      return {
        ...state,
        uploadArtImageIsInProgress: false,
        uploadArtImageError: action.data,
        uploadArtImageResponse: null
      };

    // update art
    case REQUEST_UPDATE_ART:
      return {
        ...state,
        updateArtResponse: null,
        updateArtIsInProgress: true,
        updateArtError: null
      };
    case REQUEST_UPDATE_ART_SUCCESS:
      return {
        ...state,
        updateArtIsInProgress: false,
        updateArtError: null,
        updateArtResponse: action.data
      };
    case REQUEST_UPDATE_ART_ERROR:
      return {
        ...state,
        updateArtIsInProgress: false,
        updateArtError: action.data,
        updateArtResponse: null
      };

    // delete art
    case REQUEST_DELETE_ART:
      return {
        ...state,
        deleteArtResponse: null,
        deleteArtIsInProgress: true,
        deleteArtError: null
      };
    case REQUEST_DELETE_ART_SUCCESS:
      return {
        ...state,
        deleteArtIsInProgress: false,
        deleteArtError: null,
        deleteArtResponse: action.data
      };
    case REQUEST_DELETE_ART_ERROR:
      return {
        ...state,
        deleteArtIsInProgress: false,
        deleteArtError: action.data,
        deleteArtResponse: null
      };

    // import art
    case REQUEST_IMPORT_ART:
      return {
        ...state,
        importArtList: null,
        importArtIsInProgress: true,
        importArtError: null
      };
    case REQUEST_IMPORT_ART_SUCCESS:
      return {
        ...state,
        importArtList: action.data,
        importArtIsInProgress: false,
        importArtError: null
      };
    case REQUEST_IMPORT_ART_ERROR:
      return {
        ...state,
        importArtList: null,
        importArtIsInProgress: false,
        importArtError: action.data
      };

    // export art
    case REQUEST_EXPORT_ART:
      return {
        ...state,
        exportArtList: null,
        exportArtIsInProgress: true,
        exportArtError: null
      };
    case REQUEST_EXPORT_ART_SUCCESS:
      return {
        ...state,
        exportArtIsInProgress: false,
        exportArtError: null,
        exportArtList: action.data
      };
    case REQUEST_EXPORT_ART_ERROR:
      return {
        ...state,
        exportArtIsInProgress: false,
        exportArtError: action.data,
        exportArtList: null
      };

    default:
      return state;
  }
};

export default artsReducer;
